import { Button, Modal, Carousel, Input, Select, Spin, Table } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useRef, useState, memo, useEffect, useMemo, Fragment } from "react";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { keys, isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store";

import "./index.scss";
import {
  actionResetExpediaMappingRoomId,
  getExpediaRoomInfo,
  selectStateExpediaRoomInfo,
  setExpediaTargetRoomMappingRoomId,
} from "../../../store/roomExpediaMapping";

interface IViewRoomEditor {
  open: boolean;
  viewRoomEditorInfo: any[];
  handleCloseViewRoomEditor: Function;
  handleReloadSelectTargetRoomId: Function;
  targetRoomName: string;
}
const IMAGE_SLIDE_SHOW = 6;

type LANGUAGE = "EN" | "KO" | null;

interface dynamicKeyObject {
  [key: string | number]: string;
}

const optionLanguage = [
  {
    label: "영문",
    value: "EN",
    language: 1,
  },
  {
    label: "한글",
    value: "KO",
    language: 2,
  },
];

const dropdownStyle = {
  zIndex: 1200,
};

const columnsRoomName = [
  {
    title: "Supplier Room Name",
    dataIndex: "supplierRoomName",
    key: "supplierRoomName",
  },
  {
    title: "Supplier Room Id",
    dataIndex: "supplierRoomId",
    key: "supplierRoomId",
    width: 150,
  },
];

function ViewRoomEditor(props: IViewRoomEditor) {
  const {
    open,
    handleCloseViewRoomEditor,
    viewRoomEditorInfo,
    targetRoomName,
    handleReloadSelectTargetRoomId,
  } = props;
  const dispatch = useAppDispatch();
  const stateExpediaRoomInfo = useAppSelector(selectStateExpediaRoomInfo);

  const carouselRef = useRef<any>();
  const ckEditorRef = useRef<any>();

  const [currentLanguage, setCurrentLanguage] = useState<LANGUAGE>("EN");
  const [currentCkData, setCurrentCkData] = useState<string>("");
  const [roomName, setRoomName] = useState<dynamicKeyObject>({});
  const [selectRowRoom, setSelectRowRoom] = useState<dynamicKeyObject>({});
  const [currentPage, setCurrentPage] = useState<number>(1);

  const paginationConfig = {
    defaultPageSize: 10,
    showTotal: (total: number, range: any) =>
      `${range[0]}-${range[1]} of ${total} items`,
    showSizeChanger: false,
    pageSizeOptions: ["10"],
    current: currentPage,
    onChange: (page: number) => {
      setCurrentPage(page);
    },
  };

  const getKeysImagesFromRoom = () => {
    const imagesKey = keys(images);
    while (imagesKey.length < IMAGE_SLIDE_SHOW) {
      imagesKey.push("IMAGE_EMPTY");
    }
    return imagesKey;
  };

  const handleSelectLanguage = (value: LANGUAGE) => {
    setCurrentLanguage(value);
  };

  const handleSetExpediaTargetRoomId = () => {
    dispatch(
      setExpediaTargetRoomMappingRoomId({
        id: +selectRowRoom.targetRoomId,
        roomInfoId: +selectRowRoom.supplierRoomId,
      })
    );
    handleCloseViewRoomEditor();
  };
  const handleResetExpediaTargetRoomId = () => {
    dispatch(
      setExpediaTargetRoomMappingRoomId({
        id: +selectRowRoom.targetRoomId,
        roomInfoId: null,
      })
    );
    handleCloseViewRoomEditor();
  };
  const images = useMemo(
    () => stateExpediaRoomInfo.data[0]?.image ?? {},
    [stateExpediaRoomInfo]
  );

  const customModalFooter = (
    <div className="view-room__footer">
      <Button
        type="primary"
        key="1"
        onClick={() => {
          handleCloseViewRoomEditor();
        }}
      >
        Close
      </Button>
      <Button
        type={
          !(
            selectRowRoom?.supplierRoomInfoByTargetRoom ===
            selectRowRoom?.supplierRoomId
          )
            ? "primary"
            : "default"
        }
        key="3"
        onClick={handleSetExpediaTargetRoomId}
        disabled={
          !!(
            selectRowRoom?.supplierRoomInfoByTargetRoom ===
            selectRowRoom?.supplierRoomId
          )
        }
      >
        Default
      </Button>

      {!isEmpty(selectRowRoom) &&
      selectRowRoom?.supplierRoomInfoByTargetRoom ===
        selectRowRoom?.supplierRoomId ? (
        <Button
          type="primary"
          key="2"
          onClick={() => {
            handleResetExpediaTargetRoomId();
          }}
        >
          Clear
        </Button>
      ) : (
        <Fragment />
      )}
    </div>
  );

  useEffect(() => {
    const language = optionLanguage.find(
      (item) => item.value === currentLanguage
    )?.language;
    const roomDes =
      stateExpediaRoomInfo.data.find(
        (roomItem) => roomItem.language === language
      )?.roomDes ?? "";
    setCurrentCkData(roomDes);

    // handle ckeditor
    const editor = ckEditorRef.current?.editor;
    if (!!editor) {
      editor.editing.view.scrollToTheSelection();
    }
  }, [currentLanguage, stateExpediaRoomInfo, ckEditorRef]);

  useEffect(() => {
    let currentRoomName: dynamicKeyObject = {};
    for (const { language, value } of optionLanguage) {
      const roomNameExpedia = stateExpediaRoomInfo.data.find(
        (dataItem) => dataItem.language === language
      )?.originalName;
      currentRoomName[value] = roomNameExpedia;
    }
    setRoomName(currentRoomName);
  }, [stateExpediaRoomInfo]);

  useEffect(() => {
    if (viewRoomEditorInfo[0]?.supplierRoomId && open === true) {
      handleSelectLanguage("EN");
      setSelectRowRoom(viewRoomEditorInfo[0]);
      setCurrentPage(1);
      dispatch(
        getExpediaRoomInfo({
          roomId: viewRoomEditorInfo[0]?.supplierRoomId,
          language: optionLanguage.map((item) => item.language),
        })
      );
    }
  }, [dispatch, open, viewRoomEditorInfo]);

  useEffect(() => {
    if (stateExpediaRoomInfo.expediaMappingRoomId === "SUCCESS") {
      handleReloadSelectTargetRoomId();
      dispatch(actionResetExpediaMappingRoomId());
    }
  }, [
    dispatch,
    stateExpediaRoomInfo.expediaMappingRoomId,
    handleReloadSelectTargetRoomId,
  ]);
  const getClassNameForRoom = (record: any, selectRowRoom: any) => {
    let classNameForRoom = "table-row-light";
    if (record.roomId === selectRowRoom?.roomId)
      classNameForRoom += " ant-table-row-selected";
    if (record.score === 0) classNameForRoom += " text-hight-success";
    return classNameForRoom;
  };
  return (
    <Modal
      zIndex={1100}
      width="96vw"
      title={targetRoomName}
      centered
      open={open}
      onCancel={(e) => {
        handleCloseViewRoomEditor();
      }}
      footer={customModalFooter}
    >
      <div
        id="view-room-editor"
        className={stateExpediaRoomInfo.loading ? "view-room-loading" : ""}
      >
        <div className="loading-spin">
          <Spin size="large" />
        </div>
        <div className="view-room__layout">
          <div className="view-room__all-name">
            <Table
              rowClassName={(record) =>
                getClassNameForRoom(record, selectRowRoom)
              }
              onRow={(record) => ({
                onClick: () => {
                  setSelectRowRoom(record);
                  dispatch(
                    getExpediaRoomInfo({
                      roomId: record.supplierRoomId,
                      language: optionLanguage.map((item) => item.language),
                    })
                  );
                },
              })}
              dataSource={viewRoomEditorInfo}
              columns={columnsRoomName}
              rowKey="roomId"
              size="small"
              sticky
              pagination={paginationConfig}
              scroll={{
                x: "100%",
                y: 500,
              }}
            />
          </div>
          <div className="view-room__container">
            <div className="header-input">
              <div>
                <h4>Room name</h4>
                <Input
                  placeholder="twin beds"
                  value={roomName["EN"]}
                  className="view-room__name"
                />
              </div>
              <div>
                <h4>Room name in korean</h4>
                <Input
                  placeholder="twin beds"
                  value={roomName["KO"]}
                  className="view-room__name"
                />
              </div>
              <div>
                <h4>Description</h4>
                <Select
                  style={{ width: 180 }}
                  onSelect={handleSelectLanguage}
                  options={optionLanguage}
                  value={currentLanguage}
                  dropdownStyle={dropdownStyle}
                />
              </div>
            </div>
            <div className="room__ck-editor">
              <CKEditor
                ref={ckEditorRef}
                editor={ClassicEditor}
                data={currentCkData}
                onReady={(editor) => {}}
                onChange={(event, editor) => {}}
                disabled={true}
              />
            </div>

            <div className="view-room__slider">
              <Carousel
                slidesToShow={6}
                ref={carouselRef}
                draggable
                dots={false}
              >
                {getKeysImagesFromRoom().map((imageKey) => {
                  if (imageKey !== "IMAGE_EMPTY") {
                    return (
                      <div key={imageKey} className="slider-item">
                        <img src={images[imageKey]} alt={imageKey} />
                      </div>
                    );
                  } else
                    return (
                      <div
                        key={imageKey}
                        className="slider-item empty-image"
                      ></div>
                    );
                })}
              </Carousel>
              {keys(images).length > IMAGE_SLIDE_SHOW ? (
                <div className="slider-navigate">
                  <button
                    onClick={() => {
                      carouselRef.current.next();
                    }}
                  >
                    <CaretLeftOutlined />
                  </button>
                  <button
                    onClick={() => {
                      carouselRef.current.prev();
                    }}
                  >
                    <CaretRightOutlined />
                  </button>
                </div>
              ) : (
                <Fragment />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default memo(ViewRoomEditor);
