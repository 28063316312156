import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { RootState } from "./index";
import request from "../util/request";

export interface IParamsGetRoomsOfTargetRoom {
  targetRoomId: number;
}

export interface IParamsUpdateTargetRoom {
  id: number;
  originalName: string;
}

export interface IParamsAddTargetRoom {
  id?: number;
  originalName: string;
  hotelId: number;
}

export interface IInitialState {
  idRoomsOfTargetRoom: number[],
  isDeletedTargetRoom: boolean,
  isUpdateTargetRoom: boolean,
  isCreateTargetRoom: boolean,
  targetRoomEdit: any,
  targetRoomAdd: any,
  statusRevert: boolean,
  currentRecordAction: any,
  idRoomMappedOfHotel: number[],
  targetRoomsHotel: any[];
}

const initialState: IInitialState = {
  idRoomsOfTargetRoom: [],
  isDeletedTargetRoom: false,
  isUpdateTargetRoom: false,
  isCreateTargetRoom: false,
  targetRoomEdit: {},
  targetRoomAdd: {},
  statusRevert: false,
  currentRecordAction: null,
  idRoomMappedOfHotel: [],
  targetRoomsHotel: [],
};

export const getTargetRoomsByIds = async (rules: Set<number>) => {
  const response = await request({
    url: "/target-room/get-rooms-by-ids?roomIds=" + Array.from(rules).join(","),
    method: "GET",
  });
  return response;
};

export const getTargetRoomsHotel = createAsyncThunk(
  "room/getTargetRoomsHotel",
  async (hotelId:number, { rejectWithValue }) => {
    try {
      return await request({
        url: `/target-room?size=3000&hotelId=${hotelId}`,
        method: "GET",
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMappedRooms = createAsyncThunk(
  "targetRoom/getMappedRooms",
  async (targetRoomId: number, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/target-room/get-mapped-rooms/${targetRoomId}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllMappedRooms = createAsyncThunk(
  "targetRoom/getAllMappedRooms",
  async (targetIds: number[], { rejectWithValue }) => {
    try {
      const promises = targetIds.map(id => {
        return request({
          url: `/target-room/get-mapped-rooms/${id}`,
          method: "GET",
        })
      });
      const response = await Promise.all(promises);
      return response
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTargetRoom = createAsyncThunk(
  "targetRoom/updateTargetRoom",
  async (data: IParamsUpdateTargetRoom, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/target-room`,
        method: "PUT",
        data,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTargetRoom = createAsyncThunk(
  "targetRoom/createTargetRoom",
  async (data: IParamsAddTargetRoom, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/target-room`,
        method: "POST",
        data,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTargetRoomById = createAsyncThunk(
  "targetRoom/deleteTargetRoomById",
  async (targetRoomId: number, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/target-room/${targetRoomId}`,
        method: "DELETE",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const revertTargetRoom = createAsyncThunk(
  "targetRoom/revertTargetRoom",
  async (targetRoomId: number, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/target-room/${targetRoomId}`,
        method: "POST",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "targetRoom",
  initialState,
  reducers: {
    actionIsEditTargetRoom(state, action)  {
      state.targetRoomEdit = action.payload;
    },
    actionIsCloneTargetRoom(state, action) {
      state.targetRoomAdd = action.payload;
    },
    actionResetIsCloneTargetRoom(state) {
      state.targetRoomAdd = {};
    },
    actionResetEditTargetRoom(state) {
      state.targetRoomEdit = {};
    },
    actionResetDeleteTargetRoom(state) {
      state.isDeletedTargetRoom = false;
    },
    actionResetStatusUpdateTargetRoom(state) {
      state.isUpdateTargetRoom = false;
    },
    actionResetStatusCreateTargetRoom(state) {
      state.isCreateTargetRoom = false;
    },
    actionResetStatusRevert(state) {
      state.statusRevert = false;
    },
    actionSetCurrentRecordAction(state, action) {
      state.currentRecordAction = action.payload;
    },
    actionResetCurrentRecordAction(state) {
      state.currentRecordAction = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMappedRooms.fulfilled, (state, action) => {
        state.idRoomsOfTargetRoom = get(action, "payload.data");
      })
      .addCase(getMappedRooms.rejected, (state, action) => {
        state.idRoomsOfTargetRoom = [];
      })
      .addCase(getAllMappedRooms.fulfilled, (state, action) => {
        let idsRoomMapped: any[] = []
        for (const targetRoom of action.payload) {
          if(targetRoom.status === 200 && targetRoom.data.length) {
            idsRoomMapped = idsRoomMapped.concat(targetRoom.data);
          }
        }
        state.idRoomMappedOfHotel = idsRoomMapped
      })
      .addCase(getAllMappedRooms.rejected, (state, action) => {
        state.idRoomMappedOfHotel = []
      })
      .addCase(deleteTargetRoomById.fulfilled, (state) => {
        state.isDeletedTargetRoom = true;
      })
      .addCase(deleteTargetRoomById.rejected, (state) => {
        state.isDeletedTargetRoom = true;
      })
      .addCase(updateTargetRoom.fulfilled, (state) => {
        state.isUpdateTargetRoom = true;
      })
      .addCase(updateTargetRoom.rejected, (state) => {
        state.isUpdateTargetRoom = true;
      })
      .addCase(createTargetRoom.fulfilled, (state) => {
        state.isCreateTargetRoom = true;
      })
      .addCase(createTargetRoom.rejected, (state) => {
        state.isCreateTargetRoom = true;
      })
      .addCase(revertTargetRoom.fulfilled, (state) => {
        state.statusRevert = true;
      })
      .addCase(revertTargetRoom.rejected, (state) => {
        state.statusRevert = false;
      })
      .addCase(getTargetRoomsHotel.fulfilled, (state, action) => {
        state.targetRoomsHotel = get(action, 'payload.data');
      })
      .addCase(getTargetRoomsHotel.rejected, (state) => {
        state.targetRoomsHotel = [];
      });
  },
});

export const {
  actionIsEditTargetRoom,
  actionResetEditTargetRoom,
  actionResetDeleteTargetRoom,
  actionResetStatusUpdateTargetRoom,
  actionIsCloneTargetRoom,
  actionResetIsCloneTargetRoom,
  actionResetStatusCreateTargetRoom,
  actionResetStatusRevert,
  actionSetCurrentRecordAction,
  actionResetCurrentRecordAction,

} = slice.actions;

export const selectedIdRoomsOfTargetRoom = (state: RootState) =>
  state.targetRoom.idRoomsOfTargetRoom;

export const selectedIsDeletedTargetRoom = (state: RootState) => state.targetRoom.isDeletedTargetRoom;

export const selectTargetRoomEdit = (state: RootState) => state.targetRoom.targetRoomEdit;

export const selectedIsUpdateTargetRoom = (state: RootState) => state.targetRoom.isUpdateTargetRoom;

export const selectedTargetRoomAdd = (state: RootState) => state.targetRoom.targetRoomAdd;

export const selectedIsCreateTargetRoom = (state: RootState) => state.targetRoom.isCreateTargetRoom;

export const selectedStatusRevert = (state: RootState) => state.targetRoom.statusRevert;

export const selectedCurrentRecordAction = (state: RootState) => state.targetRoom.currentRecordAction;

export const selectedIdRoomMappedOfHotel = (state: RootState) => state.targetRoom.idRoomMappedOfHotel;

export const selectedTargetRoomsHotel = (state: RootState) => state.targetRoom.targetRoomsHotel;

export default slice.reducer;
