import React, {  useContext, useEffect, useRef, useState } from 'react'
import { Form, Input, InputRef } from 'antd';
import { EditableContext } from '../context/EditableContext';
import { DataRule } from '../Rules';

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DataRule;
  record: DataRule;
  handleSave: (record: DataRule) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);
  let childNode: any = children;
  
  useEffect(() => {
    if (editing) inputRef.current!.focus();
  }, [editing]);

  const closeInputEdit = () => {
    setEditing(false);
    form.setFieldsValue({ [`${record.key}.${dataIndex}`]: record[dataIndex] });
  };

  const openInputEdit = () => {
    setEditing(true);
    form.setFieldsValue({ [`${record.key}.${dataIndex}`]: record[dataIndex] });
  }

  const save = async () => {
    try {
      const values = await form.validateFields([`${record.key}.${dataIndex}`]);
      const keyValue = Object.keys(values)[0]
      const keyValueSplit = keyValue.split(".")
      const orgininalKey =  keyValueSplit[keyValueSplit.length - 1]
      closeInputEdit();
      // value
      handleSave({ ...record, [orgininalKey]: values[keyValue] });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  if (editable) {
    childNode = (editing ||  childNode[1].length === 0) ? (
      <Form.Item
        style={{ margin: 0 }}
        name={`${record.key}.${dataIndex}`}
        rules={[{ required: true, message: `${title} is required.` }]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="rules-editable-cell-value-wrap"
        style={{
          border: childNode[1].length === 0 ? "1px solid #ccc" : "none",
        }}
        onClick={openInputEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell