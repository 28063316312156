import { useEffect, useState } from 'react';
import URL from '../../constants/URL';
import { Navigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store/index';
import { selectIsLogin, actionLoginKeyCloak } from '../../store/authSlice';
import { Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import {LoginOutlined} from '@ant-design/icons';
import "./index.css";
import logoImage from '../../image/logo.png'

function Login() {
  const urlSignIn = process.env.REACT_APP_URL_SIGN_IN_PROVIDER ?? '';
  const dispatch = useAppDispatch();
  const [code, setCode] = useState('');
  const isLogin = useAppSelector(selectIsLogin);

  function signIn() {
    const {width, height} = window.screen;
    const w = 1000;
    const h = 600;
    let left = (width/2)-(w/2);
    let top = (height/2)-(h/2);
    window.open(urlSignIn, 'login', 'toolbar=0, resizable=0, status=1,, width='+w+', height='+h+', top='+top+', left='+left);
  }

  useEffect(() => {    
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.type === 'sendCode') {
          const { code } = event.data
          setCode(code);
        }
      },
      false
    );
  }, [])

  useEffect(() => {
    if(code.length) {
      dispatch(actionLoginKeyCloak(code));
    }
  }, [code, dispatch]);

  if (isLogin) {
    return <Navigate to={URL.Home} replace />;
  }

  return (
    <div className="gx-main-content-wrapper">
      <div className="gx-login-container">
        <div className="gx-login-content">
          <Title className="flex text-center"><img src={logoImage} alt="" /></Title>
          <div className="flex text-center" onClick={signIn}>
            <Button type="primary" icon={<LoginOutlined />}>
              Sign In
            </Button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Login;

