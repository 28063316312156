import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Table, Tabs } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import HeaderLayout from "../../common/HeaderLayout";
import { RULES_DIMENSIONS, RULE_TARGET } from "../../constants/ClassifiedDimensionConst";
import EditableRow from "./components/EditableRow";
import EditableCell from "./components/EditableCell";
import { addNewRule, deleteRuleById, getRules, selectAllRules } from "../../store/rulesSlice";
import { EditableContext } from "./context/EditableContext";
import { useAppDispatch, useAppSelector } from "../../store";
import "./Rules.css";

export interface DataRule {
  key?: React.Key;
  id?: number;
  dimension?: string;
  roomNameEn: string;
  roomNameKr: string;
}

const defaultColumns = [
  {
    title: "영문 코드명",
    dataIndex: "roomNameEn",
    key: "roomNameEn",
    width: "50%",
    editable: true,
  },
  {
    title: "한글 코드명",
    dataIndex: "roomNameKr",
    key: "roomNameKr",
    width: "50%",
    editable: true,
  },
];

function Rules() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let params = useParams();

  const [form] = Form.useForm();
  const hotelId = params.hotelId ? parseInt(params.hotelId) : RULE_TARGET.GENERAL_RULE;
  const { rules, pending, refresh } = useAppSelector(selectAllRules);

  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false)
  const [rulesData, setRulesData] = useState<DataRule[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isAddNewRow, setIsAddNewRow] = useState<boolean>(false)
  const [rulesDemension, setRulesDemension] = useState<string>(
    RULES_DIMENSIONS[0]
  );

  const columns = defaultColumns.map((col) => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: (record: DataRule) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  function onChangeRulesDemension(demension: string) {
    setRulesDemension(demension);
  }

  function onSelectChange(newSelectedRowKeys: React.Key[]) {
    const lastSelectedRowKey = newSelectedRowKeys.slice(-1);
    setSelectedRowKeys(lastSelectedRowKey);
  }

  function openModal() {
    setOpenModalDelete(true)
  }

  function closeModal() {
    setOpenModalDelete(false)
  }

  function addNewRowRule() {
    const lengthFilterRuleData = filterRuleData.length;
    const newRowRule: DataRule = {
      key: `${lengthFilterRuleData}.${rulesDemension}`,
      dimension: rulesDemension,
      roomNameEn: "",
      roomNameKr: "",
    };
    setIsAddNewRow(true)
    setRulesData((prev: DataRule[]) => [...prev, newRowRule]);
  }

  async function hanldeConfirm() {
    try {
      const values = await form.validateFields();
      const newData = rulesData.filter(
        (item) => item.dimension === rulesDemension
      );
      const transformData = newData.map((item: any) => {
        const { key, ...rest } = item;
        if (!item?.id) return { hotelId, ...rest };
        return rest;
      });
      dispatch(
        addNewRule({
          ruleManagementList: transformData,
        })
      );
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  }

  const handleSave = (row: DataRule) => {
    const newData = [...rulesData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setRulesData(newData);
  };

  function deleteRowRule() {
    setOpenModalDelete(false)
    if (selectedRowKeys.length === 0) return;
    const rowSelected = rulesData.find((rule) => rule.key === selectedRowKeys[0])
    if (!rowSelected?.id) {
      const filterData = rulesData?.filter((rule) => rule.key !== selectedRowKeys[0])
      setRulesData(filterData)
    } else {
      const keyRule = (selectedRowKeys[0] as string)?.split(".")[0];
      dispatch(deleteRuleById({ Id: Number(keyRule) }));
    }
  }

  const filterRuleData = useMemo(
    () => rulesData.filter((item) => item.dimension === rulesDemension),
    [rulesData, rulesDemension]
  );

  useEffect(() => {
    dispatch(getRules({ hotelId }));
  }, [dispatch, refresh, hotelId]);

  useEffect(() => {
    if (rules.length > 0) {
      const transformRules = rules.map((filterData: any) => ({
        key: `${filterData?.id}.${filterData?.dimension}.${filterData.roomNameEn}.${filterData.roomNameKr}`,
        ...filterData,
      }));
      setRulesData(transformRules);
    }
  }, [rules]);

  useEffect(() => {
    if (isAddNewRow) {
      const lastRow = rulesData.slice(-1)
      const lastInput =  document.querySelector<HTMLElement>(`[data-row-key="${lastRow[0].key}"] .ant-form-item-control-input-content input`)
      if (lastInput) {
        lastInput?.focus()
      }
      setIsAddNewRow(false)
    }
  },[isAddNewRow])

  return (
    <div className="rules">
      <HeaderLayout title="Filter Management" onBack={() => navigate("/")}>
        <div className="rules-header-container">
          <Tabs
            defaultActiveKey="1"
            onChange={onChangeRulesDemension}
            items={RULES_DIMENSIONS.map((dimension: string) => {
              const label = dimension;
              const key = dimension;
              return { label, key };
            })}
          />
          <div className="rules-header-action">
            <Button icon={<PlusCircleOutlined />} className="rules-btn-add" onClick={addNewRowRule}>
              추가
            </Button>
            <Button icon={<MinusCircleOutlined />} className="rules-btn-delete" onClick={openModal}>
              삭제
            </Button>
            <Button type="primary" onClick={hanldeConfirm}>
              저장
            </Button>
          </div>
        </div>
      </HeaderLayout>
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <Table
            rowKey="key"
            id="rules-table"
            loading={pending}
            rowClassName="rules-editable-row"
            components={{ body: { row: EditableRow, cell: EditableCell } }}
            columns={columns}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
              hideSelectAll: true,
            }}
            dataSource={filterRuleData}
            scroll={{ y: "calc(100vh - 300px)" }}
            pagination={false}
          />
        </EditableContext.Provider>
      </Form>

      <Modal open={openModalDelete} centered footer={null} closeIcon={<></>} width={400} onCancel={closeModal}>
        <p>해당 코드를 삭제하시겠습니까?</p>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="text" onClick={closeModal}>취소</Button>
          <Button type="primary" onClick={deleteRowRule}>확인</Button>
        </div>
      </Modal>
    </div>
  );
}

export default Rules;
