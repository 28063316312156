import './App.scss';
import Home from './admin/Home';
import { Routes, Route } from "react-router-dom";
import MainLayout from './common/MainLayout';
import RoomManagement from './admin/room-management/RoomManagement';
import TargetRoomManagement from './admin/room-management/TargetRoomManagement';
import MappingResult from './admin/room-management/MappingResult';
import useWebSocket from 'react-use-websocket';
import { ReactElement, Suspense, useState } from 'react';
import RuleManagement from './admin/rule-management/RuleManagement';
import Login from './admin/login';
import URL from './constants/URL';
import PrivateRoute from './layouts/PrivateRoute';
import DefaultLayout from './layouts/DefaultLayout';
import RedirectedLogin from './page/RedirectedLogin';
import InsertTargetRoom from './admin/room-management/InsertTargetRoom'
import Rules from './admin/rules/Rules';
interface ItemType {
  key: string;
  components: ReactElement;
  layout: string;
  private: boolean;
}

// --------------------------------------------------------------------------------- //

const DEFAULT_LAYOUT = 'default';
const NONE_LAYOUT = 'none';

// --------------------------------------------------------------------------------- //

function App() {
  const socketUrl = process.env.REACT_APP_WSS_URL ?? '';
  const [statusRoomChange, setStatusRoomChange] = useState();
  const { sendJsonMessage } = useWebSocket(socketUrl, {
    onMessage: (e: MessageEvent) => {
      console.log(e.data)
      if (e.data) {
        const data = e.data.split(',')
        const connectionId = data[0]
        const action = data[1]
        if (action === 'init')
          localStorage.setItem('connectionId', connectionId)
        else
          setStatusRoomChange({
            action: action,
            connectionId: connectionId,
            data: data.slice(2).map(Number)
          } as any)
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  const itemsRoute: ItemType[] = [
    {
      key: URL.Home,
      components: <Home statusRoomChange={statusRoomChange} />,
      layout: DEFAULT_LAYOUT,
      private: true,
    },
    {
      key: URL.TargetRoomManagement,
      components: <TargetRoomManagement sendWssMessage={sendJsonMessage} />,
      layout: DEFAULT_LAYOUT,
      private: true,
    },
    {
      key: URL.RoomManagement,
      components: <RoomManagement sendWssMessage={sendJsonMessage} />,
      layout: DEFAULT_LAYOUT,
      private: true,
    },
    {
      key: URL.MappingResult,
      components: <MappingResult />,
      layout: DEFAULT_LAYOUT,
      private: true,
    },
    {
      key: URL.RuleManagement,
      components: <RuleManagement />,
      layout: DEFAULT_LAYOUT,
      private: true,
    },
    {
      key: URL.Login,
      components: <Login />,
      layout: NONE_LAYOUT,
      private: false,
    },
    {
      key: URL.RedirectedLogin,
      components: <RedirectedLogin />,
      layout: NONE_LAYOUT,
      private: false,
    },
    {
      key: URL.InsertTargetRoom,
      components: <InsertTargetRoom/>,
      layout: DEFAULT_LAYOUT,
      private: true,
    },
    // New Rules Page
    {
      key: URL.Rules,
      components: <Rules />,
      layout: DEFAULT_LAYOUT,
      private: true,
    },
  ];

  return (
    <MainLayout>
      <Routes>
        {itemsRoute.map((item) => {
          let element = item.components;
          element = <Suspense fallback={null}>{element}</Suspense>;
          if (item.layout === DEFAULT_LAYOUT) {
            element = <DefaultLayout>{element}</DefaultLayout>;
          }
          if (item.private) {
            element = <PrivateRoute>{element}</PrivateRoute>;
          }
          return <Route key={item.key} path={item.key} element={element} />;
        })}
      </Routes>
    </MainLayout>
  );
}

export default App;
