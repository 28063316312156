import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../util/request";
import { get } from "lodash";
import { RootState } from ".";

interface IParamsDeleteRuleById {
  Id: number
}

interface IParamsGetRules {
  hotelId: number
}

interface IRule {
  id: number;
  roomNameEn: string;
  hotelId?: number;
  roomNameKr: string;
  dimension: string;
}

interface IInitialStateRules {
  rules: IRule[];
  pending: boolean;
  refresh: boolean;
}

interface IPayloadAddNewRule {
  id: number;
  enName: string;
  krName: string;
  dimension: string | undefined;
}

const initialState: IInitialStateRules = {
  rules: [],
  pending: false,
  refresh: false
};

export const getRules = createAsyncThunk(
  "rules/getRules",
  async (params: IParamsGetRules, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/filter-management/${params.hotelId}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRuleById = createAsyncThunk(
  "rules/deleteRuleById",
  async (params: IParamsDeleteRuleById, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/filter-management/${params.Id}`,
        method: "DELETE",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewRule = createAsyncThunk(
  "rules/addNewRule",
  async (data: {ruleManagementList: IPayloadAddNewRule[], hotelId?: number}, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/filter-management`,
        method: "POST",
        data
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const slice = createSlice({
  name: "rules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRules.pending, (state) => {
        state.pending = true
      })
      .addCase(getRules.fulfilled, (state, action) => {
        state.pending = false
        const data = get(action, "payload.data");
        state.rules = data
      })
      .addCase(getRules.rejected, (state) => {
        state.pending = false
      })

      .addCase(deleteRuleById.pending, (state) => {
        state.pending = true
      })
      .addCase(deleteRuleById.fulfilled, (state, action) => {
        state.pending = false
        state.refresh = !state.refresh
      })
      .addCase(deleteRuleById.rejected, (state) => {
        state.pending = false
      })

      .addCase(addNewRule.pending, (state) => {
        state.pending = true
      })
      .addCase(addNewRule.fulfilled, (state, action) => {
        state.pending = false
        state.refresh = !state.refresh
      })
      .addCase(addNewRule.rejected, (state) => {
        state.pending = false
      });
  },
});

// export const {} = slice.actions;

export const selectAllRules = (state: RootState) => state.rules

export default slice.reducer;