import { ReactNode } from "react";
import { Layout } from 'antd';

const { Content } = Layout;

// Layout
const MainLayout = ({ children }: { children: ReactNode }) => (
    <Layout>
        <Content className="site-layout">
            <div className="site-layout-background">
                {children}
            </div>
        </Content>
    </Layout>
);

export default MainLayout