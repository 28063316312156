import { useState, useEffect, useRef, useCallback } from "react";
import { Table, Button, Row, Col, Switch } from "antd";
import scrollIntoView from "scroll-into-view";
import {
  MANDATORY_DIMENSIONS,
  RULE_TARGET,
} from "../../constants/ClassifiedDimensionConst";
import { useNavigate, useParams } from "react-router-dom";
import CreateRuleFunctionMenu from "./components/CreateRuleFunctionMenu";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import HeaderLayout from "../../common/HeaderLayout";
import RuleList from "./components/RuleList";
import { STATUS_TARGET_ROOM } from "../../constants/RoomConst";
import { RetweetOutlined } from "@ant-design/icons";
import {
  compareTwoRule,
  getFilterRules,
  getSortedRulesFollowAlphaBetaWithCombinations,
} from "../../helper/RuleListHelper";
import { useAppDispatch, useAppSelector } from "../../store/index";
import {
  actionResetStatusUpdateTargetRoom,
  getMappedRooms,
  selectedIdRoomsOfTargetRoom,
  selectedStatusRevert,
  selectTargetRoomEdit,
  actionResetDeleteTargetRoom,
  selectedTargetRoomAdd,
  selectedIsCreateTargetRoom,
  actionResetStatusCreateTargetRoom,
  selectedIsUpdateTargetRoom,
  selectedIsDeletedTargetRoom,
  actionResetEditTargetRoom,
  actionResetStatusRevert,
  actionSetCurrentRecordAction,
  selectedCurrentRecordAction,
  selectedIdRoomMappedOfHotel,
  actionResetCurrentRecordAction,
  getAllMappedRooms,
  getTargetRoomsHotel,
  selectedTargetRoomsHotel,
} from "../../store/targetRoomSlice";
import {
  getRoomsByIds,
  selectedListRoomsByIds,
  selectedLoadingListRoomById,
  resetListRoomsByTargetRoom,
  getRoomsHotel,
  selectedListRoomsHotel,
  getSupplierRoomInfoByTargetRoom,
  selectSupplierRoomInfoByTargetRoom,
} from "../../store/roomSlice";
import { cloneDeep, isEmpty } from "lodash";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PopupContext from "../../common/PopupContext/targetRoom";
import EditTargetRoom from "../../common/EditTargetRoom";
import {
  selectedDeletedRule,
  actionResetDeleteRule,
} from "../../store/ruleSlice";
import CreateTargetRoom from "../../common/CreateTargetRoom";
import {
  selectedStatusRunMapping,
  actionResetStatusRunMapping,
} from "../../store/engineResultSlice";
import {
  ID_TARGET_ROOM_NOT_MAPPED,
  LIMIT_PAGESIZE_DEFAULT,
} from "../../constants/app";
import MatchTargetRoomRecommendation from "./components/MatchTargetRoomRecommendation";
import {
  findRuleAssumption,
  format,
  getClassNameSetTarget,
  getNoneProcessedTokens,
  getRecommendTargetRooms,
} from "../../helper/RoomHelper";
import { actionShowHotel, selectedHotelDetail } from "../../store/hotelSlice";
import { RULE_TYPE } from "../../constants/Rule";
import ViewRoomEditor from "./components/ViewRoomEditor";
import { IRulesData } from "./RoomManagement";
import { getRules, selectAllRules } from "../../store/rulesSlice";
interface IProps {
  sendWssMessage: SendJsonMessage;
}
interface IviewRoomEditor {
  supplierRoomIdByTargetRoom: number;
  supplierRoomId: number;
  roomId: number;
  targetRoomId: number;
  supplierRoomName: string;
  score: number;
}

function TargetRoomManagement(props: IProps) {
  let currentPage = 1;
  let params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hotelId = params.hotelId
    ? parseInt(params.hotelId)
    : RULE_TARGET.GENERAL_RULE;
  const [data, setData] = useState([]);
  const [pendingRule, setPendingRule] = useState({
    target: hotelId,
    input: "",
    value: "",
    dimension: "GRADE",
    assumption: false,
    referenceIds: [] as any[],
    type: RULE_TYPE.NORMAL,
    nameRuleAssumption: "",
  });

  const [ruleAll, setRuleAll] = useState<any>([]);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [targetRoomSelected, setTargetRoomSelected] = useState<any>({});
  const [loadingTargetRoom, setLoadingTargetRoom] = useState(false);
  const [checkViewDetailTargetRoom, setCheckViewDetailTargetRoom] =
    useState(false);
  const [mapTargetRoomAndMappedRoom, setMapTargetRoomAndMappedRoom] =
    useState<any[]>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const idRoomsOfTargetRoom = useAppSelector(selectedIdRoomsOfTargetRoom);
  const listRoomByIds = useAppSelector(selectedListRoomsByIds);
  const loadingListRoomById = useAppSelector(selectedLoadingListRoomById);
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const deletedRule = useAppSelector(selectedDeletedRule);
  const isDeleteTargetRoom = useAppSelector(selectedIsDeletedTargetRoom);
  const targetRoomEdit = useAppSelector(selectTargetRoomEdit);
  const isUpdateTargetRoom = useAppSelector(selectedIsUpdateTargetRoom);
  const targetRoomAdd = useAppSelector(selectedTargetRoomAdd);
  const isCreateTargetRoom = useAppSelector(selectedIsCreateTargetRoom);
  const statusRevert = useAppSelector(selectedStatusRevert);
  const currentRecordAction = useAppSelector(selectedCurrentRecordAction);
  const idRoomMappedOfHotel = useAppSelector(selectedIdRoomMappedOfHotel);
  const listRoomsHotel = useAppSelector(selectedListRoomsHotel);
  const targetRoomsHotel = useAppSelector(selectedTargetRoomsHotel);
  const hotelDetail = useAppSelector(selectedHotelDetail);
  const supplierRoomInfoByTargetRoom = useAppSelector(
    selectSupplierRoomInfoByTargetRoom
  );
  const [stateListRoomByids, setStateListRoomByIds] = useState<any>();
  const [popupContextMenu, setPopupContextMenu] = useState({
    record: {},
    visible: false,
    x: 0,
    y: 0,
  });

  const [rulesData, setRulesData] = useState<IRulesData[]>([]);
  const { rules } = useAppSelector(selectAllRules);
  const statusRunMapping = useAppSelector(selectedStatusRunMapping);

  const [viewRoomEditorInfo, setViewRoomEditorInfo] = useState<
    IviewRoomEditor[]
  >([]);
  const [openViewRoomEditor, setOpenViewRoomEditor] = useState(false);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: 100,
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (event: any) => {
            if (targetRoomSelected.id === record.id) {
              setTargetRoomSelected({});
              return;
            }
            setTargetRoomSelected(record);
          },
        };
      },
    },
    {
      title: "Target Room name",
      dataIndex: "originalName",
      width: 400,
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (ev: any) => {
            if (!isEmpty(targetRoomEdit)) {
              return;
            }
            if (targetRoomSelected.id === record.id) {
              setTargetRoomSelected({});
              return;
            }
            setTargetRoomSelected(record);
          },
          onContextMenu: (event: any) => {
            event.preventDefault();
            if (!isEmpty(targetRoomEdit) && targetRoomEdit.id === record.id) {
              return;
            }
            if (!popupContextMenu.visible) {
              document.addEventListener(`click`, function onClickOutside() {
                setPopupContextMenu({
                  ...popupContextMenu,
                  visible: false,
                });
                document.removeEventListener(`click`, onClickOutside);
              });
            }
            setPopupContextMenu({
              ...popupContextMenu,
              record,
              visible: true,
              x: event.clientX,
              y: event.clientY,
            });
          },
        };
      },
      render: (originalName: any, record: any) => (
        <>
          {targetRoomEdit.id === record.id && (
            <EditTargetRoom record={record} />
          )}
          {targetRoomEdit.id !== record.id && originalName}
        </>
      ),
    },
    {
      title: "Mandotary tags",
      dataIndex: "rules",
      width: 680,
      render: (rules: any, record: any) => {
        const ruleModify = findRuleAssumption(ruleAll, rules);
        return (
          <RuleList
            rules={ruleModify}
            isMandatory={true}
            onClick={onClickUnUsedToken}
            isContextMenu={true}
            record={record}
          />
        );
      },
      defaultSortOrder: "ascend" as any,
      sorter: (room1: any, room2: any) => {
        if (room1.status - room2.status !== 0)
          return room1.status - room2.status;
        else if (room1.duplicateGroup && !room2.duplicateGroup) return -1;
        else if (!room1.duplicateGroup && room2.duplicateGroup) return 1;
        else {
          for (const [i, ruleRoom1] of Object.entries(room1.rules)) {
            const ruleRoom2 = room2.rules[i];
            if (ruleRoom1 && !ruleRoom2) return -1;
            else if (ruleRoom2 && !ruleRoom1) return 1;
            else {
              const resultCompareRules = compareTwoRule(ruleRoom1, ruleRoom2);
              if (resultCompareRules !== 0) return resultCompareRules;
            }
          }
        }
        return 0;
      },
      filters: [
        {
          text: "Assumption Rules",
          value: "3",
        },
        {
          text: "Other",
          value: "0",
        },
      ],
      defaultFilteredValue: ["0", "3"],
      onFilter: (value: any, record: any) => {
        const findAssumptionRules = record.rules.find((rule: any) => {
          return rule.type === RULE_TYPE.ASSUMPTION;
        });
        if (value === "0") {
          return !findAssumptionRules;
        }
        if (value === "3") {
          return !!findAssumptionRules;
        }
        return true;
      },
    },
    {
      title: "Filter",
      dataIndex: "filterRule",
      width: 400,
      render: (rules: any, record: any) => {
        const { filterRuleModfify, filterRuleNotMandatory } = getFilterRules(
          record,
          targetRoomsHotel,
          ruleAll,
          rulesData
        );
        return (
          <>
            <RuleList
              rules={filterRuleModfify}
              isMandatory={true}
              onClick={onClickUnUsedToken}
              isContextMenu={true}
              record={record}
            />
            <RuleList
              rules={filterRuleNotMandatory}
              isMandatory={false}
              onClick={onClickUnUsedToken}
              isContextMenu={true}
              record={record}
            />
          </>
        );
      },
    },
    {
      title: "Addional Tags",
      dataIndex: "rules",
      width: 400,
      render: (rules: any, record: any) => {
        const ruleModify = findRuleAssumption(ruleAll, rules);
        return (
          <RuleList
            rules={ruleModify}
            isMandatory={false}
            onClick={onClickUnUsedToken}
            isContextMenu={true}
            record={record}
          />
        );
      },
    },
    {
      title: "Unprocessed Tokens",
      dataIndex: "noneProccessedTokens",
      width: 400,
      render: (noneProccessedTokens: Array<string>, record: any) =>
        noneProccessedTokens.map((token: string, index: number) => (
          <Button
            key={index}
            type="dashed"
            style={{ display: "inline-block" }}
            onClick={() => {
              onClickUnUsedToken({
                input: [token],
                referenceId: [],
                type: RULE_TYPE.NORMAL,
              });
              dispatch(actionSetCurrentRecordAction(record));
            }}
          >
            {token}
          </Button>
        )),
      sorter: (room1: any, room2: any) => {
        return (
          room2["noneProccessedTokens"].length -
          room1["noneProccessedTokens"].length
        );
      },
    },
    {
      title: "Room Info",
      dataIndex: 'roomInfoId',
      width: 100,
      render: (roomInfoId: number) => roomInfoId ? 'Y' : ''
    }
  ];

  const columnsShowTargetRoom: any = [
    {
      title: "ID",
      dataIndex: "id",
      width: 100,
      render: (id: number, record: any) => {
        if (
          (record.isTargetRoomId && record.id !== ID_TARGET_ROOM_NOT_MAPPED) ||
          !record.isTargetRoomId
        ) {
          return <>{record.id}</>;
        }
      },
    },
    {
      title: "Target Room name",
      dataIndex: "originalName",
      width: 500,
      render: (originalName: any, record: any) => (
        <>
          {record.isTargetRoomId && (
            <>
              {record.id === ID_TARGET_ROOM_NOT_MAPPED ? (
                <strong>
                  {originalName}(Not Mapped: {record.children.length})
                </strong>
              ) : (
                <strong>
                  {originalName}(Mapped: {record.children.length})
                </strong>
              )}
            </>
          )}
          {!record.isTargetRoomId &&
            (!record.targetRoomId ? (
              <MatchTargetRoomRecommendation
                targetRooms={record.targetRoomRecommendations}
                percentage={record.percentage}
                invertPercentage={record.invertPercentage}
                roomName={record.name}
                roomTaggedRules={record.rules}
                children={<>{originalName}</>}
              />
            ) : (
              <>{originalName}</>
            ))}
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 100,
      render: (price: any, record: any) => (
        <>
          <strong>
            {record.isTargetRoomId && record.id !== ID_TARGET_ROOM_NOT_MAPPED
              ? format(record.minPriceRoom)
              : ""}
          </strong>
          {!record.isTargetRoomId ? format(record.price) : ""}
        </>
      ),
    },
    {
      title: "Supplier",
      dataIndex: "sprName",
      width: 100,
    },
    {
      title: "Mandotary tags",
      dataIndex: "rules",
      width: 680,
      render: (rules: any, record: any) => {
        const ruleModify = findRuleAssumption(ruleAll, rules);
        return (
          <RuleList
            rules={ruleModify}
            isMandatory={true}
            onClick={onClickUnUsedToken}
            record={record}
            isContextMenu={false}
          />
        );
      },
    },
    {
      title: "Filter",
      dataIndex: "filterRule",
      width: 400,
      render: (rules: any, record: any) => {
        const targetData = [...data, ...listRoomsHotel];
        const { filterRuleModfify, filterRuleNotMandatory } = getFilterRules(
          record,
          targetData,
          ruleAll,
          rulesData
        );
        return (
          <>
            <RuleList
              rules={filterRuleModfify}
              isMandatory={true}
              onClick={onClickUnUsedToken}
              isContextMenu={true}
              record={record}
            />
            <RuleList
              rules={filterRuleNotMandatory}
              isMandatory={false}
              onClick={onClickUnUsedToken}
              isContextMenu={true}
              record={record}
            />
          </>
        );
      },
    },
    {
      title: "Addional Tags",
      dataIndex: "rules",
      width: 400,
      render: (rules: any, record: any) => {
        const ruleModify = findRuleAssumption(ruleAll, rules);
        return (
          <RuleList
            rules={ruleModify}
            isMandatory={false}
            onClick={onClickUnUsedToken}
            record={record}
            isContextMenu={false}
          />
        );
      },
    },
    {
      title: "Unprocessed Tokens",
      dataIndex: "noneProccessedTokens",
      width: 400,
      render: (noneProccessedTokens: Array<string>, record: any) =>
        noneProccessedTokens.map((token: string, index: number) => (
          <Button
            key={index}
            type="dashed"
            style={{ display: "inline-block" }}
            onClick={() => {
              onClickUnUsedToken({
                input: [token],
                referenceId: [],
                type: RULE_TYPE.NORMAL,
              });
            }}
          >
            {token}
          </Button>
        )),
    },
  ];

  const columnsRoom = [
    {
      title: "Id",
      dataIndex: "id",
      width: 100,
    },
    {
      title: "Room name",
      dataIndex: "originalName",
      width: 300,
    },
    {
      title: "Mandotary tags",
      dataIndex: "rules",
      width: 500,
      render: (rules: any, record: any) => {
        const ruleModify = findRuleAssumption(ruleAll, rules);
        return (
          <RuleList
            rules={ruleModify}
            isMandatory={true}
            onClick={onClickUnUsedToken}
            isContextMenu={true}
          />
        );
      },
    },
    {
      title: "Supplier",
      dataIndex: "sprName",
      width: 132,
    },
    // {
    //   title: "Supplier Room Id",
    //   dataIndex: "room_id",
    //   width: 132,
    //   render: (roomId: string | number, record: any) => {
    //     if (!record.room_id) return 0;
    //     return roomId;
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   width: 80,
    //   render: (text: string, record: any) => {
    //     return <div className="g-text-center">{text}</div>;
    //   },
    // },
  ];

  function onClickUnUsedToken(token: any) {
    if (pendingRule.input.length && pendingRule.type !== token.type) {
      if (token.type === RULE_TYPE.NORMAL) {
        const inputRule = token.input.reduce(
          (acc: string, currentValue: string) => acc + currentValue,
          ""
        );
        setPendingRule((pendingRule) => ({
          ...pendingRule,
          input: token.input.length ? inputRule : "",
          type: token.type,
          referenceIds: [],
          assumption: false,
        }));
      }

      if (token.type === RULE_TYPE.ASSUMPTION) {
        const inputRule = token.input.reduce(
          (acc: string, currentValue: string) => acc + " " + currentValue,
          ""
        );
        const referenceIds = pendingRule.referenceIds.concat([
          token.referenceId,
        ]);
        setPendingRule((pendingRule) => ({
          ...pendingRule,
          input: token.input.length ? inputRule : "",
          type: token.type,
          referenceIds: referenceIds,
          assumption: true,
        }));
      }
    } else {
      if (token.type === RULE_TYPE.NORMAL) {
        const inputRule = token.input.reduce(
          (acc: string, currentValue: string) => acc + currentValue,
          ""
        );
        setPendingRule((pendingRule) => ({
          ...pendingRule,
          input: (pendingRule.input + " " + inputRule).trim(),
          type: token.type,
          referenceIds: [],
          assumption: false,
        }));
      }
      if (
        token.type === RULE_TYPE.ASSUMPTION &&
        !pendingRule.referenceIds.includes(token.referenceId)
      ) {
        const referenceIds = pendingRule.referenceIds.concat([
          token.referenceId,
        ]);
        const inputRule = token.input.reduce(
          (acc: string, currentValue: string) => acc + " " + currentValue,
          ""
        );
        setPendingRule((pendingRule) => ({
          ...pendingRule,
          input: (pendingRule.input + " " + inputRule).trim(),
          type: token.type,
          referenceIds: referenceIds,
          assumption: true,
        }));
      }
    }
  }

  const sortRulesFollowInputLengthThenTarget = (rules: Array<any>) => {
    return rules.length < 2
      ? rules
      : rules.sort((previousRule: any, currentRule: any) => {
          if (currentRule.input.length - previousRule.input.length === 0)
            return currentRule.input.target - currentRule.input.target;
          return currentRule.input.length - previousRule.input.length;
        });
  };

  const checkDuplicateThenFlag = (listRooms: Array<any>) => {
    let duplicateGroup = 1;
    for (let index = 0; index < listRooms.length; index++) {
      const room = listRooms[index];
      if (
        index > 0 &&
        room["mandatoryRuleIds"].length > 0 &&
        room["mandatoryRuleIds"].every(
          (v: number, i: number) =>
            v === listRooms[index - 1]["mandatoryRuleIds"][i]
        )
      ) {
        if (!listRooms[index - 1]["duplicateGroup"]) {
          listRooms[index - 1]["duplicateGroup"] = duplicateGroup;
          duplicateGroup++;
        }
        room["duplicateGroup"] = listRooms[index - 1]["duplicateGroup"];
      }
    }
  };

  const sortForSearchDuplicateMandotaryRulesInRooms = (
    listRooms: Array<any>
  ) => {
    listRooms.forEach((room: any) => {
      room.mandatoryRuleIds = room.rules
        .filter((rule: any) => MANDATORY_DIMENSIONS.includes(rule.dimension))
        .map((rule: any) => rule.referenceId)
        .sort((a: number, b: number) => a - b);
    });
    listRooms = listRooms.sort((room1: any, room2: any) => {
      if (room1.status - room2.status !== 0) return room1.status - room2.status;
      else if (room1.duplicateGroup && !room2.duplicateGroup) return -1;
      else if (!room1.duplicateGroup && room2.duplicateGroup) return 1;
      else {
        if (
          room2.mandatoryRuleIds.length - room1.mandatoryRuleIds.length !==
          0
        ) {
          return room2.mandatoryRuleIds.length - room1.mandatoryRuleIds.length;
        } else {
          for (let index = 0; index < room2.mandatoryRuleIds.length; index++) {
            if (room2.mandatoryRuleIds[index] !== room1.mandatoryRuleIds[index])
              return (
                room2.mandatoryRuleIds[index] - room1.mandatoryRuleIds[index]
              );
          }
        }
      }
      return 0;
    });
    return listRooms;
  };

  const onBack = () => {
    props.sendWssMessage({
      action: "out",
      hotelId: hotelId,
    });
    navigate("/");
  };

  let lastDuplicateGroupId = 0;
  let lastColor = "table-row-1";

  const getClassNameForRoom = (
    roomStatus: number,
    duplicateGroup: number,
    idRoom: number
  ) => {
    let classNameForRoom = "table-row-light";
    if (roomStatus === STATUS_TARGET_ROOM.DELETED)
      classNameForRoom = "table-row-grey";
    else if (duplicateGroup) {
      const color =
        duplicateGroup === lastDuplicateGroupId
          ? lastColor
          : lastColor === "table-row-2"
          ? "table-row-1"
          : "table-row-2";
      lastDuplicateGroupId = duplicateGroup;
      lastColor = color;
      classNameForRoom = color;
    }
    if (idRoom === targetRoomSelected.id) {
      classNameForRoom += " ant-table-row-selected";
    }
    return classNameForRoom;
  };

  function onChangeViewTargetRoom(checked: boolean) {
    setCheckViewDetailTargetRoom(checked);
  }

  function splitRoomMapped(arrRooms: any, roomMinPriceMapped: any) {
    let firstRooms: any = [];
    let lastRooms: any = [];
    if (roomMinPriceMapped) {
      firstRooms = arrRooms.filter((room: any) => {
        return room.price <= roomMinPriceMapped.price;
      });
      lastRooms = arrRooms.filter((room: any) => {
        return room.price > roomMinPriceMapped.price;
      });
    }
    return [firstRooms, lastRooms];
  }

  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = cloneDeep(expandedRowKeys);
    if (expanded) {
      keys.push(record.key);
    } else {
      let index = keys.indexOf(record.key);
      if (index !== -1) {
        keys.splice(index, 1);
      }
    }
    setExpandedRowKeys(keys);
  };

  const handleCloseViewRoomEditor = useCallback(() => {
    setOpenViewRoomEditor(false);
  }, []);
  const handleReloadSelectTargetRoomId = useCallback(() => {
    if (!isEmpty(targetRoomSelected)) {
      dispatch(getMappedRooms(targetRoomSelected.id));
      // update SupplierRoomInfoByTargetRoom
      dispatch(getSupplierRoomInfoByTargetRoom(targetRoomSelected.id));
    }
  }, [targetRoomSelected]);

  useEffect(() => {
    if (!checkViewDetailTargetRoom) setViewRoomEditorInfo([]);
    const uniqueRoomIdMap = new Map<number,any>();
    const newListRoom = cloneDeep(listRoomByIds).sort((a: { score: number }, b: { score: number }) => a.score - b.score)
    for (const obj of newListRoom) {
      const key = obj.room_id;
      if (!uniqueRoomIdMap.has(key)) {
        uniqueRoomIdMap.set(key, obj);
      }
    }
    const uniqueListRoom= Array.from(uniqueRoomIdMap.values());
    const newViewRoomEditor: any = uniqueListRoom
      .map((item: any) => ({
        supplierRoomId: item.room_id,
        roomId: item.id,
        supplierRoomName: item.supplierRoomName,
        targetRoomId: targetRoomSelected.id,
        supplierRoomInfoByTargetRoom: supplierRoomInfoByTargetRoom,
        score: item.score,
      }))

    setViewRoomEditorInfo(newViewRoomEditor);
  }, [checkViewDetailTargetRoom, listRoomByIds, supplierRoomInfoByTargetRoom]);


  useEffect(() => {
    const init = async () => {
      dispatch(getRoomsHotel(hotelId));
      dispatch(getTargetRoomsHotel(hotelId));
      dispatch(actionShowHotel(hotelId));
      dispatch(getRules({ hotelId }));
      props.sendWssMessage({
        action: "join",
        hotelId: hotelId,
      });
    };
    init();
  }, []);

  useEffect(() => {
    let listRooms: any = cloneDeep(targetRoomsHotel);
    /* setRuleAll */
    let ruleAll: any[] = [];
    if (listRooms.length) {
      for (const room of listRooms.concat(listRoomsHotel)) {
        const rule = (room as any).rules.length ? (room as any).rules : [];
        ruleAll = ruleAll.concat(rule);
      }
    }
    setRuleAll(ruleAll);

    listRooms.forEach((room: any) => {
      let roomName = room.name;
      room.rules = sortRulesFollowInputLengthThenTarget(room.rules);
      room["noneProccessedTokens"] = getNoneProcessedTokens(
        room.rules,
        roomName
      );
    });
    listRooms = sortForSearchDuplicateMandotaryRulesInRooms(listRooms);
    checkDuplicateThenFlag(listRooms);
    listRooms.forEach((room: any) => {
      room.isTargetRoomId = true;
      room.rules = getSortedRulesFollowAlphaBetaWithCombinations(room.rules);
    });
    setData(listRooms);

    // TODO: get room of target room
    let targetRoomIds = listRooms
      .filter((room: any) => room.status !== 7)
      .map((room: any) => room.id);
    dispatch(getAllMappedRooms(targetRoomIds));
  }, [dispatch, targetRoomsHotel]);

  useEffect(() => {
    if (currentRecordAction && currentRecordAction.id && data.length) {
      scrollIntoView(
        document.querySelector(
          `[data-row-key="${currentRecordAction.id}"]`
        ) as HTMLElement,
        {
          align: {
            top: 0,
          },
        }
      );
      dispatch(actionResetCurrentRecordAction());
    }
  }, [currentRecordAction, data, dispatch]);

  useEffect(() => {
    if (!isEmpty(targetRoomSelected)) {
      dispatch(getMappedRooms(targetRoomSelected.id));
      dispatch(getSupplierRoomInfoByTargetRoom(targetRoomSelected.id));
    }
  }, [dispatch, targetRoomSelected]);

  useEffect(() => {
    if (idRoomsOfTargetRoom.length) {
      dispatch(
        getRoomsByIds({
          roomIds: idRoomsOfTargetRoom,
        })
      );
    } else {
      dispatch(resetListRoomsByTargetRoom());
    }
  }, [dispatch, idRoomsOfTargetRoom]);

  useEffect(() => {
    if (
      deletedRule ||
      isUpdateTargetRoom ||
      isDeleteTargetRoom ||
      isCreateTargetRoom ||
      statusRevert
    ) {
      setLoadingTargetRoom(true);
      setTimeout(() => {
        dispatch(getTargetRoomsHotel(hotelId));
        setLoadingTargetRoom(false);
      }, 3000);
    }
    if (deletedRule) {
      dispatch(actionResetDeleteRule());
      return;
    }
    if (isUpdateTargetRoom) {
      dispatch(actionResetEditTargetRoom());
      dispatch(actionResetStatusUpdateTargetRoom());
      return;
    }
    if (isDeleteTargetRoom) {
      dispatch(actionResetDeleteTargetRoom());
      return;
    }
    if (isCreateTargetRoom) {
      dispatch(actionResetStatusCreateTargetRoom());
      setIsModalAddOpen(false);
      return;
    }
    if (statusRunMapping) {
      dispatch(actionResetStatusRunMapping());
      return;
    }
    if (statusRevert) {
      dispatch(actionResetStatusRevert());
      return;
    }
  }, [
    deletedRule,
    isCreateTargetRoom,
    isDeleteTargetRoom,
    isUpdateTargetRoom,
    statusRevert,
    statusRunMapping,
  ]);

  useEffect(() => {
    if (!isEmpty(targetRoomAdd)) {
      setIsModalAddOpen(true);
    } else {
      setIsModalAddOpen(false);
    }
  }, [targetRoomAdd]);
  useEffect(() => {
    if (idRoomMappedOfHotel.length && checkViewDetailTargetRoom) {
      dispatch(
        getRoomsByIds({
          roomIds: idRoomMappedOfHotel,
        })
      );
    } else {
      dispatch(resetListRoomsByTargetRoom());
    }
  }, [checkViewDetailTargetRoom, dispatch, idRoomMappedOfHotel]);

  useEffect(() => {
    if (checkViewDetailTargetRoom) {
      let dataTargetRoom = cloneDeep(data);
      let dataAllRoomMapped = cloneDeep(listRoomByIds);
      let listRoomsHotelClone = cloneDeep(listRoomsHotel);
      const idsRoomMapped = dataAllRoomMapped.map((el: any) => el.id);
      let dataRoomNotMapped = listRoomsHotelClone.filter((room: any) => {
        if (!idsRoomMapped.includes(room.id)) {
          return room;
        }
      });

      // TODO: modify data of room mapped
      dataAllRoomMapped.forEach((room: any) => {
        let roomName = room.name;
        room.rules = sortRulesFollowInputLengthThenTarget(room.rules);
        room.rules.forEach(
          (rule: any) => (roomName = roomName.replaceAll(rule.input, ""))
        );
        const noneProcessedRoomName = roomName.replace(/  +/g, " ").trim();
        room["noneProccessedTokens"] =
          noneProcessedRoomName.length > 0
            ? noneProcessedRoomName.split(" ")
            : [];
        room.rules = getSortedRulesFollowAlphaBetaWithCombinations(room.rules);
      });

      // TODO: modify data of room not mapped
      dataRoomNotMapped.forEach((room: any) => {
        let roomName = room.name;
        room.rules = sortRulesFollowInputLengthThenTarget(room.rules);
        room.rules.forEach(
          (rule: any) => (roomName = roomName.replaceAll(rule.input, ""))
        );
        const noneProcessedRoomName = roomName.replace(/  +/g, " ").trim();
        room["noneProccessedTokens"] =
          noneProcessedRoomName.length > 0
            ? noneProcessedRoomName.split(" ")
            : [];

        const targetRoomRecommendations = getRecommendTargetRooms(
          room,
          dataTargetRoom.filter((targetRoom: any) => targetRoom.status !== 7)
        ).slice(0, 8);
        let percentage = targetRoomRecommendations.length
          ? targetRoomRecommendations[0].percentage
          : 0;
        let invertPercentage = targetRoomRecommendations.length
          ? targetRoomRecommendations[0].invertPercentage
          : 0;
        room["targetRoomMappingPercentage"] = percentage;
        room["targetRoomRecommendations"] = targetRoomRecommendations;
        room["percentage"] = percentage;
        room["invertPercentage"] = invertPercentage;
      });
      dataRoomNotMapped = dataRoomNotMapped.sort((a: any, b: any) => {
        return a.price - b.price;
      });

      // TODO: assign rooms with corresponding target room
      let mapTargetRoomAndMappedRoom = dataTargetRoom
        .filter((targetRoom: any) => targetRoom.status !== 7)
        .map((targetRoom: any) => {
          targetRoom.key = `targetRoom-${targetRoom.id}-${targetRoom.name}`;
          // Sort rooms by price from small to large
          targetRoom.children = dataAllRoomMapped
            .filter((data: any) => data.targetRoomId === targetRoom.id)
            .sort((a: any, b: any) => {
              return a.price - b.price;
            });
          targetRoom.minPriceRoom = targetRoom.children.length
            ? targetRoom.children[0].price
            : 0;
          return targetRoom;
        });

      mapTargetRoomAndMappedRoom = mapTargetRoomAndMappedRoom.sort((a, b) => {
        return a.minPriceRoom - b.minPriceRoom;
      });

      // TODO:
      mapTargetRoomAndMappedRoom = mapTargetRoomAndMappedRoom.sort((a, b) => {
        if (a.children.length === 0 && b.children.length !== 0) return 1;
        else if (b.children.length === 0 && a.children.length !== 0) return -1;
        else return 0;
      });

      // TODO: find room mapped and min price
      let roomMinPriceMapped = null;
      if (dataAllRoomMapped.length) {
        roomMinPriceMapped = dataAllRoomMapped.length
          ? dataAllRoomMapped.reduce((previous: any, current: any) => {
              return current.price < previous.price ? current : previous;
            })
          : null;
      }

      // TODO: split room not Mapped > minPriceMapped
      const [firstRooms, lastRooms] = splitRoomMapped(
        dataRoomNotMapped,
        roomMinPriceMapped
      );
      if (firstRooms.length) {
        mapTargetRoomAndMappedRoom.unshift({
          key: `targetRoom-${ID_TARGET_ROOM_NOT_MAPPED}-etc-first`,
          id: ID_TARGET_ROOM_NOT_MAPPED,
          name: "etc",
          originalName: "ETC",
          isTargetRoomId: true,
          rules: [],
          hotel: null,
          status: 1,
          noneProccessedTokens: [],
          mandatoryRuleIds: [],
          children: firstRooms,
        });
      }

      if (lastRooms.length) {
        mapTargetRoomAndMappedRoom.push({
          key: `targetRoom-${ID_TARGET_ROOM_NOT_MAPPED}-etc-last`,
          id: ID_TARGET_ROOM_NOT_MAPPED,
          name: "etc",
          originalName: "ETC",
          isTargetRoomId: true,
          rules: [],
          hotel: null,
          status: 1,
          noneProccessedTokens: [],
          mandatoryRuleIds: [],
          children: lastRooms,
        });
      }
      if (mapTargetRoomAndMappedRoom.length) {
        let keysExpanded = mapTargetRoomAndMappedRoom.map(
          (room: any) => room.key
        );
        setExpandedRowKeys(keysExpanded);
      }
      setMapTargetRoomAndMappedRoom(mapTargetRoomAndMappedRoom);
    }
  }, [checkViewDetailTargetRoom, data, listRoomByIds, listRoomsHotel]);

  useOnClickOutside(ref, () => {
    if (!openViewRoomEditor) {
      setTargetRoomSelected({});
    }
  });

  useEffect(() => {
    if (rules.length > 0) {
      const transformRulesData = rules.map((item: any) => ({
        dimension: item?.dimension,
        value: item?.roomNameEn,
        roomNameKr: item?.roomNameKr,
      }));
      setRulesData(transformRulesData);
    }
  }, [rules]);

  useEffect(() => {
    if (!isEmpty(targetRoomSelected)) {
      const newListRoomByids = listRoomByIds.map((item: any) => {
        if (
          supplierRoomInfoByTargetRoom &&
          item.room_id === supplierRoomInfoByTargetRoom
        )
          return { ...item, status: "Mapped" };
        else return { ...item, status: "-" };
      });
      setStateListRoomByIds(newListRoomByids);
    }
  }, [targetRoomSelected, listRoomByIds, supplierRoomInfoByTargetRoom]);

  return (
    <div className="Home">
      <HeaderLayout
        title={`${hotelId}. ${hotelDetail.name} - Target Room Management`}
        onBack={onBack}
        extraFuncs={[
          <Button
            type="primary"
            key={1}
            shape="round"
            icon={<RetweetOutlined />}
            onClick={() => {
              window.location.reload();
            }}
          >
            F5
          </Button>,
          <a
            className="ant-btn"
            key={2}
            href={"/room-management/" + hotelId}
            target={"_blank"}
            rel="noreferrer"
          >
            Rooms
          </a>,
          <a
            className="ant-btn"
            key={3}
            href={"/filter-management/" + hotelId}
            target={"_blank"}
            rel="noreferrer"
          >
            Filter Management
          </a>,
        ]}
      >
        <CreateRuleFunctionMenu
          pendingRule={pendingRule}
          setPendingRule={setPendingRule}
          currentPage={currentPage}
        />
        <Switch
          checked={checkViewDetailTargetRoom}
          onChange={onChangeViewTargetRoom}
          key={4}
        />
      </HeaderLayout>
      {!checkViewDetailTargetRoom && (
        <Row gutter={8} ref={ref}>
          <Col
            className="gutter-row"
            span={isEmpty(targetRoomSelected) ? 24 : 12}
          >
            <Table
              loading={loadingTargetRoom}
              rowClassName={(record) =>
                getClassNameForRoom(
                  record.status,
                  record.duplicateGroup,
                  record.id
                )
              }
              size="small"
              rowKey="id"
              style={{ marginTop: "10px" }}
              columns={columns}
              dataSource={data}
              pagination={{ defaultPageSize: LIMIT_PAGESIZE_DEFAULT }}
              scroll={{ y: window.innerHeight - 350 }}
            />
            <CreateTargetRoom
              show={isModalAddOpen}
              targetRoomAdd={targetRoomAdd}
            />
          </Col>
          <Col
            className="gutter-row"
            span={isEmpty(targetRoomSelected) ? 0 : 12}
          >
            <Button
              type="primary"
              style={{ marginTop: 10, marginBottom: 10, float: "right" }}
              onClick={() => {
                setOpenViewRoomEditor(true);
              }}
            >
              Room Info
            </Button>
            <Table
              size="small"
              rowKey="id"
              rowClassName={(record) => getClassNameSetTarget(record)}
              loading={loadingListRoomById}
              style={{ marginTop: "10px" }}
              columns={columnsRoom}
              dataSource={stateListRoomByids}
              pagination={false}
              scroll={{ y: window.innerHeight - 350 }}
            />
          </Col>
        </Row>
      )}
      {checkViewDetailTargetRoom && (
        <Row gutter={8} ref={ref}>
          <Col className="gutter-row" span={24}>
            <Table
              style={{ marginTop: "10px" }}
              loading={loadingListRoomById}
              size="small"
              columns={columnsShowTargetRoom}
              dataSource={mapTargetRoomAndMappedRoom}
              expandable={{
                defaultExpandAllRows: true,
              }}
              expandedRowKeys={expandedRowKeys}
              pagination={false}
              scroll={{ y: window.innerHeight - 350 }}
              onExpand={onTableRowExpand}
              rowClassName={(record) => getClassNameSetTarget(record)}
            />
          </Col>
        </Row>
      )}
      <PopupContext {...popupContextMenu} />
      <ViewRoomEditor
        open={openViewRoomEditor}
        targetRoomName={targetRoomSelected?.originalName}
        viewRoomEditorInfo={viewRoomEditorInfo}
        handleCloseViewRoomEditor={handleCloseViewRoomEditor}
        handleReloadSelectTargetRoomId={handleReloadSelectTargetRoomId}
      />
    </div>
  );
}

export default TargetRoomManagement;
