export const STATUS_ROOM_NAME = {
    NOT_FINISH_CLASSIFIED: 0, //not contain pending rule
    NOT_FINISH_CLASSIFIED_HAVE_PENDING_RULE: 1,
    CLASSIFIED_CANNOT_MAPPED: 2,
    CLASSIFIED_AUTO_MAPPED_NOT_VERIFIED_BY_ADMIN: 3,
    CLASSIFIED_VERIFIED_BY_ADMIN: 10
}

export const STATUS_ROOM = {
    STATUS_ADDED: 0,
    STATUS_FINISH_TAGGING: 0,
    STATUS_FINISH_MATCHING: 0,
    UNMMAPED: 7,
    STATUS_MANUAL_MAPPING: 3,
}

export const STATUS_TARGET_ROOM = {
    ENABLE: 0,
    DELETED: 7
}