import { useState, useEffect } from "react";
import { Table, TablePaginationConfig, Tabs, Tag } from "antd";
import { RULE_MANAGEMENT_DIMENSIONS, RULE_TARGET } from "../../constants/ClassifiedDimensionConst";
import HeaderLayout from "../../common/HeaderLayout";
import { useNavigate, useParams } from "react-router-dom";
import { getRules } from "../../store/ruleSlice";

function RuleManagement() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dimension] = useState(RULE_MANAGEMENT_DIMENSIONS[0]);
  let params = useParams();
  const hotelId = params.hotelId
    ? parseInt(params.hotelId)
    : RULE_TARGET.GENERAL_RULE;

  const columns = [
    {
      title: "Value",
      dataIndex: "value",
    },
    {
      title: "Input",
      dataIndex: "inputval",
      render: (inputval: any, record: any) => (
        <>
          {inputval.split(",").map((tag: string) => {
            const temp = tag.split("-");
            const tagVal = temp[0];
            const target = parseInt(temp[1]);

            let color = target > 0 ? "geekblue" : "green";
            if (record.value === "IGNORE") {
              color = "red";
            }
            return (
              <Tag color={color} key={tag}>
                {tagVal}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  const onChangePage = async (
    pagination: TablePaginationConfig  ) => {
    const response = await getRules(hotelId, pagination.current === undefined ? 0 : pagination.current - 1, dimension);
    setData(response.data.content as any);
  };

  const onChangeDimension = async (dimension: string) => {
    const rules = await getRules(hotelId, 0, dimension);
    setData(rules.data.content as any);
  };

  useEffect(() => {
    onChangeDimension(dimension);
  }, [dimension]);

  return (
    <div className="Home">
      <HeaderLayout title="Rule Management" onBack={() => navigate("/")}>
        <Tabs defaultActiveKey="1" onChange={onChangeDimension} items={RULE_MANAGEMENT_DIMENSIONS.map((dimension: string) => {
          const label = dimension;
          const key = dimension;
          return { label, key };
        })} />
      </HeaderLayout>
      <Table
        rowKey="inputval"
        style={{ marginTop: "10px" }}
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={onChangePage}
      />
    </div>
  );
}

export default RuleManagement;
