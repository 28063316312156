import "./index.css";
import { DeleteTwoTone, EditTwoTone, FileAddTwoTone, RollbackOutlined } from "@ant-design/icons";
import {
  deleteTargetRoomById,
  actionIsEditTargetRoom,
  actionIsCloneTargetRoom,
  revertTargetRoom
} from "../../store/targetRoomSlice";
import { useAppDispatch, useAppSelector } from "../../store/index";
import { selectedStatusIsProcessing } from "../../store/engineResultSlice";
import { notification } from "antd";
import { STATUS_TARGET_ROOM } from "../../constants/RoomConst";

interface IProps {
  record: any;
  visible: boolean;
  x: number;
  y: number;
}
function PopupContext(props: IProps) {
  const dispatch = useAppDispatch();
  const { record, visible, x, y } = props;
  const isProcessing = useAppSelector(selectedStatusIsProcessing);
  const isRevert = record.status === STATUS_TARGET_ROOM.DELETED ? true : false;
  function deleteRoom() {
    if (isProcessing) {
      notification.warn({
        message:
          "Please complete the mapping process before doing other tasks delete",
        placement: "top",
      });
      return;
    }
    const confirm = window.confirm("Are you sure to delete this target room?");
    if (confirm) {
      dispatch(deleteTargetRoomById(record.id));
    }
  }
  function editRoom() {
    if (isProcessing) {
      notification.warn({
        message:
          "Please complete the mapping process before doing other tasks edit",
        placement: "top",
      });
      return;
    }
    dispatch(actionIsEditTargetRoom(record));
  }
  function cloneRoom() {
    if (isProcessing) {
      notification.warn({
        message:
          "Please complete the mapping process before doing other tasks clone",
        placement: "top",
      });
      return;
    }
    dispatch(actionIsCloneTargetRoom(record));
  }

  function revertRoom() {
    if (isProcessing) {
      notification.warn({
        message:
          "Please complete the mapping process before doing other tasks revert",
        placement: "top",
      });
      return;
    }
    dispatch(revertTargetRoom(record.id));
  }

  return (
    <>
      {visible && (
        <ul className="popup" style={{ left: `${x}px`, top: `${y}px` }}>
          <li onClick={cloneRoom}>
            <FileAddTwoTone /> Clone
          </li>
          <li onClick={editRoom}>
            <EditTwoTone /> Edit
          </li>
          <li onClick={deleteRoom}>
            <DeleteTwoTone /> Delete
          </li>
          { isRevert &&
            <li onClick={revertRoom}>
              <RollbackOutlined /> Revert
            </li>
          }
        </ul>
      )}
    </>
  );
}

export default PopupContext;
