import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./index";
import request from "../util/request";
import { get } from "lodash";

export interface IParamDeleteRoom {
  ruleId: number;
  connectionId: string;
}

export interface IPreProcessing {
  connectionId: string,
  ruleItem: {
    dimension: string,
    value: string,
    type: number,
    input: string,
    target: number,
  },
  hotelId: number,
  input: string,
}

const initialState = {
  deletedRule: false,
  statusPreProcessing: false,
  batchJobs: [],
};

// TODO: maintains function use redux thunk
export const getRules = async (hotelId: number, page: number, dimension: string | undefined, input?: string) => {
  return await request({
    url: `/rule?size=10000&page=${page}&${(dimension ? 'dimension=' + dimension : 'input=' + input)}&hotelId=${hotelId}`,
    method: "GET",
  });
}

export const create = async (rules: Array<any>, hotelId: number, connectionId: string) => {
   const response = await request({
    url: '/rule',
    method: "POST",
    data: {
      ruleItems: rules,
      hotelId: hotelId,
      connectionId: connectionId
    },
  });
  return response;
}

export const simulate = async (hotelIds: Array<number>, connectionId: string) => {
  const response = await request({
   url: '/rule/simulate',
   method: "POST",
   data: {
    hotelIds: hotelIds,
    connectionId: connectionId,
   },
 });
 return response;
}

export const getRulesByIds = async (rules: Set<unknown>) => {
  return await request({
    url: "/rule/get-rule-by-ids?ruleIds=" + Array.from(rules).join(","),
    method: "GET",
  });
}

export const rejectRule = async (hotelId: string | undefined) => {
  if(hotelId !== 'multiple') hotelId = 'single_' + hotelId + '#' + hotelId
  const response = await request({
    url: "/rule/reject-rule/" + hotelId,
    method: "DELETE",
  });
  return response;
}

export const acceptRule = async (hotelId: string | undefined) => {
  if(hotelId !== 'multiple') hotelId = 'single_' + hotelId
  const response = await request({
    url: "rule/accept-rule/" + hotelId,
    method: "PUT",
  });
  return response;
}

export const deleteRule = createAsyncThunk(
  "rule/deleteRule",
  async (params: IParamDeleteRoom, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/rule/${params.ruleId}`,
        method: "DELETE",
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const preProcessingRule = createAsyncThunk(
  "rule/preProcessingRule",
  async (data: IPreProcessing, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/rule/preprocessing',
        method: "POST",
        data,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "rule",
  initialState,
  reducers: {
    actionResetDeleteRule(state) {
      state.deletedRule = false;
    },
    actionResetStatusPreProcessing(state) {
      state.batchJobs = [];
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(deleteRule.fulfilled, (state) => {
        state.deletedRule = true;
      })
      .addCase(deleteRule.rejected, (state, action) => {
        state.deletedRule = true;
      })
      .addCase(preProcessingRule.fulfilled, (state, action) => {
        state.batchJobs = get(action, "payload.data");
      })
      .addCase(preProcessingRule.rejected, (state, action) => {
        state.batchJobs = [];
      });
  },
});

export const { actionResetDeleteRule, actionResetStatusPreProcessing } = slice.actions;

export const selectedDeletedRule = (state: RootState) =>
  state.rule.deletedRule;

export const selectedBatchJobs = (state: RootState) =>
  state.rule.batchJobs;

export default slice.reducer;
