import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function RedirectedLogin() {
  const { search } = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(search);
    const code: any = query.get('code');
    window.opener.postMessage(
      {
        type: "sendCode",
        code: code
      },
      "*"
    );
    window.close();
  }, [search]);
  return (<></>)
}
export default RedirectedLogin;