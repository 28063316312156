import { Button, Dropdown, Input, Layout, Menu, Select } from "antd";
import {
  LogoutOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "antd/lib/layout/layout";
import debounce from "lodash/debounce";
import { useAppDispatch, useAppSelector } from "../store/index";
import { actionLogout } from "../store/authSlice";

import logoImage from "../image/logo.png";
import userImage from "../image/user.png";
import { NATIONS, getNation } from "../constants/app";

import {
  actionGetCity,
  resetListCity,
  selectedListCity,
  setFilterHotel,
} from "../store/hotelSlice";

const { Content } = Layout;

interface DefaultLayoutProps {
  children: JSX.Element;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  const { children } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const listCity = useAppSelector(selectedListCity);
  const [collapsed, setCollapsed] = useState(false);
  const [filter, setFilter] = useState({
    nation: "",
    cityId: null,
    hotelId: "",
    hotelName: "",
  });
  const showNationFilter = pathname === "/";
  function handleClick(event: any) {
    const { key } = event;
    if (key === "infoUser") {
      navigate("/profile");
    } else if (key === "logout") {
      dispatch(actionLogout());
    }
  }

  const menu = (
    <Menu
      items={[
        {
          key: "infoUser",
          icon: <UserOutlined />,
          label: "Account",
        },
        {
          key: "logout",
          icon: <LogoutOutlined />,
          label: "Logout",
        },
      ]}
      onClick={handleClick}
    />
  );
  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  function handleChange(type: string, value: string) {
    if (type === "nation") {
      dispatch(value ? actionGetCity({ nation: value }) : resetListCity());
    }

    if (type === "nation" && !value) {
      setFilter({
        ...filter,
        [type]: value || "",
        cityId: null,
      });
      return;
    }
    setFilter({
      ...filter,
      [type]: value || null,
    });
    return;
  }

  const debouncedChangeHotelId = debounce((event) => {
    const value = event.target.value;
    setFilter({
      ...filter,
      hotelId: value || "",
    });
  }, 500);
  const debouncedChangeHotelName = debounce((event) => {
    const value = event.target.value;
    setFilter({
      ...filter,
      hotelName: value || "",
    });
  }, 500);

  useEffect(() => {
    dispatch(setFilterHotel({ ...filter }));
  }, [dispatch, filter]);
  return (
    <Layout>
      <Header className="layout-page-header">
        <div
          className="logo"
          style={{ width: collapsed ? 80 : 250 }}
          onClick={() => navigate("/")}
        >
          <img
            src={logoImage}
            alt=""
            style={{ marginRight: collapsed ? "2px" : "20px" }}
          />
        </div>
        <div className="layout-page-header-main">
          <div onClick={toggleCollapsed}></div>
          <div className="actions">
            {showNationFilter && (
              <>
                <Select
                  placeholder="Nation"
                  style={{ width: 150, marginRight: 10 }}
                  onChange={(value) => handleChange("nation", value)}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    ((option?.label ?? "") as any).includes(input)
                  }
                >
                  {NATIONS.map((nation) => (
                    <Select.Option
                      key={nation.value}
                      value={nation.value}
                      label={nation.label}
                    >
                      <>
                        <img src={getNation(nation.value)} alt="" width={30} />
                        {" " + nation.label}
                      </>
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  value={filter.cityId}
                  showSearch
                  placeholder="City"
                  style={{ width: 150, marginRight: 10 }}
                  onChange={(value: any) => handleChange("cityId", value)}
                  allowClear
                  filterOption={(input, option) =>
                    ((option?.label ?? "") as any).includes(input)
                  }
                >
                  {listCity.map((city) => (
                    <Select.Option
                      key={city.value}
                      value={city.value}
                      label={city.label}
                    >
                      {" "}
                      {city.label}
                    </Select.Option>
                  ))}
                </Select>
                <Input
                  placeholder="Hotel Id"
                  style={{ width: 150, marginRight: 10 }}
                  onChange={debouncedChangeHotelId}
                />
                <Input
                  placeholder="Hotel Name"
                  style={{ width: 150, marginRight: 10 }}
                  onChange={debouncedChangeHotelName}
                />
              </>
            )}
            <Button
              onClick={() =>
                navigate("/target-room-management/insertTargetRoom")
              }
              style={{ marginRight: 10, paddingTop: 1 }}
              icon={<UploadOutlined />}
            >
              Upload TargetRoom
            </Button>
            <Dropdown overlay={menu}>
              <span className="user-action">
                <img src={userImage} className="user-avator" alt="avator" />
              </span>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Layout>
        <Layout>
          <Content
            style={{
              padding: 12,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
