import { useEffect, useState } from "react";
import { Button, Table, Tag } from "antd";
import { RULE_TARGET } from "../../constants/ClassifiedDimensionConst";
import { useNavigate, useParams } from "react-router-dom";
import CreateRuleFunctionMenu from "./components/CreateRuleFunctionMenu";
import MatchTargetRoomRecommendation from "./components/MatchTargetRoomRecommendation";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import HeaderLayout from "../../common/HeaderLayout";
import RuleList from "./components/RuleList";
import { RetweetOutlined } from "@ant-design/icons";
import { STATUS_ROOM } from "../../constants/RoomConst";
import { useAppDispatch, useAppSelector } from "../../store/index";
import {
  actionResetDeleteRule,
  selectedDeletedRule,
} from "../../store/ruleSlice";
import {
  actionResetStatusRunMapping,
  selectedStatusRunMapping,
} from "../../store/engineResultSlice";
import { LIMIT_PAGESIZE_DEFAULT } from "../../constants/app";
import RoomContext from "../../common/PopupContext/room";
import {
  actionResetCurrentRecordActionRoom,
  actionResetSetTarget,
  actionResetStatusUnMap,
  actionResetUnSetTarget,
  actionSetCurrentPage,
  actionSetCurrentRecordActionRoom,
  getRoomsHotel,
  selectedCurrentPage,
  selectedCurrentRecordActionRoom,
  selectedIsSimulate,
  selectedListRoomsHotel,
  selectedRevertUnMapRoom,
  selectedSetTarget,
  selectedUnmappedRoom,
  selectedUnsetTarget,
} from "../../store/roomSlice";
import {
  compareTwoRule,
  getFilterRules,
  getSortedRulesFollowAlphaBetaWithCombinations,
} from "../../helper/RuleListHelper";
import scrollIntoView from "scroll-into-view";
import {
  findRuleAssumption,
  format,
  getClassNameSetTarget,
  getNoneProcessedTokens,
  getRecommendTargetRooms,
} from "../../helper/RoomHelper";
import ListTargetRoomWithRoom from "../../common/ListTargetRoomWithRoom";
import { actionShowHotel, selectedHotelDetail } from "../../store/hotelSlice";
import {
  getTargetRoomsHotel,
  selectedTargetRoomsHotel,
} from "../../store/targetRoomSlice";
import { cloneDeep } from "lodash";
import { RULE_TYPE } from "../../constants/Rule";
import { getRules, selectAllRules } from "../../store/rulesSlice";
import { updateExpediaRoomMappingByHotelId } from "../../store/roomExpediaMapping";

interface IProps {
  sendWssMessage: SendJsonMessage;
}

export interface IRulesData {
  dimension: string;
  value: string;
  roomNameKr: string;
}

function RoomManagement(props: IProps) {
  const currentPage = useAppSelector(selectedCurrentPage);
  let params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hotelId = params.hotelId
    ? parseInt(params.hotelId)
    : RULE_TARGET.GENERAL_RULE;
  const [ruleAll, setRuleAll] = useState<any>([]);
  const [data, setData] = useState([]);
  const [pendingRule, setPendingRule] = useState({
    target: hotelId,
    input: "",
    value: "",
    dimension: "GRADE",
    assumption: false,
    referenceIds: [] as any[],
    type: RULE_TYPE.NORMAL,
    nameRuleAssumption: "",
  });
  const [openListTargetRoom, setOpenListTargetRoom] = useState({
    record: {},
    visible: false,
    x: 0,
    y: 0,
  });

  const [popupContextMenu, setPopupContextMenu] = useState({
    record: {},
    visible: false,
    x: 0,
    y: 0,
  });

  // Rule
  const [rulesData, setRulesData] = useState<IRulesData[]>([]);

  const [loadingRoom, setLoadingRoom] = useState(false);
  const { rules } = useAppSelector(selectAllRules);
  const deletedRule = useAppSelector(selectedDeletedRule);
  const statusRunMapping = useAppSelector(selectedStatusRunMapping);
  const unmappedRoom = useAppSelector(selectedUnmappedRoom);
  const revertUnMapRoom = useAppSelector(selectedRevertUnMapRoom);
  const currentRecordActionRoom = useAppSelector(
    selectedCurrentRecordActionRoom
  );
  const setTarget = useAppSelector(selectedSetTarget);
  const unsetTarget = useAppSelector(selectedUnsetTarget);
  const hotelDetail = useAppSelector(selectedHotelDetail);
  const roomsHotel = useAppSelector(selectedListRoomsHotel);
  const targetRoomsHotel = useAppSelector(selectedTargetRoomsHotel);
  const isSimulate = useAppSelector(selectedIsSimulate);
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: 100,
    },
    {
      title: "Room name",
      dataIndex: "originalName",
      width: 300,
      onCell: (record: any, rowIndex: any) => {
        return {
          onContextMenu: (event: any) => {
            event.preventDefault();
            if (!popupContextMenu.visible) {
              document.addEventListener(`click`, function onClickOutside() {
                setPopupContextMenu({
                  ...popupContextMenu,
                  visible: false,
                });
                document.removeEventListener(`click`, onClickOutside);
              });
            }
            setPopupContextMenu({
              ...popupContextMenu,
              record,
              visible: true,
              x: event.clientX,
              y: event.clientY,
            });
          },
        };
      },
    },
    {
      title: "Target room name",
      dataIndex: "targetRoom",
      filters: [
        {
          text: "Unmatched Room",
          value: "7",
        },
        {
          text: "Matched Target",
          value: "2",
        },
        {
          text: "Matched Unfinished Tagging",
          value: "1",
        },
        {
          text: "Processing",
          value: "0",
        },
      ],
      defaultFilteredValue: ["0", "1", "7"],
      onFilter: (value: any, record: any) => {
        let filterVal = parseInt(value);
        if (filterVal >= 1)
          return record.targetRoomMappingPercentage === filterVal;
        return record.targetRoomMappingPercentage < 1;
      },
      sorter: (a: any, b: any) =>
        a.targetRoomMappingPercentage - b.targetRoomMappingPercentage,
      width: 400,
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 100,
      render: (price: any, record: any) => <>{price ? format(price) : ""}</>,
    },
    {
      title: "Supplier",
      dataIndex: "sprName",
      width: 100,
    },
    {
      title: "Mandotary tags",
      dataIndex: "rules",
      width: 500,
      render: (rules: any, record: any) => {
        const ruleModify = findRuleAssumption(ruleAll, rules);
        return (
          <RuleList
            rules={ruleModify}
            isMandatory={true}
            onClick={onClickUnUsedToken}
            isContextMenu={true}
            record={record}
          />
        );
      },
      defaultSortOrder: isSimulate ? undefined : ("ascend" as any),
      sorter: (room1: any, room2: any) => {
        if (!isSimulate) {
          if (room1.status - room2.status !== 0)
            return room1.status - room2.status;
          else {
            for (const [i, ruleRoom1] of Object.entries(room1.rules)) {
              const ruleRoom2 = room2.rules[i];
              if (ruleRoom1 && !ruleRoom2) return -1;
              else if (ruleRoom2 && !ruleRoom1) return 1;
              else {
                const resultCompareRules = compareTwoRule(ruleRoom1, ruleRoom2);
                if (resultCompareRules !== 0) return resultCompareRules;
              }
            }
          }
          return 0;
        }
      },
      filters: [
        {
          text: "Assumption Rules",
          value: "3",
        },
        {
          text: "Other",
          value: "0",
        },
      ],
      defaultFilteredValue: ["0", "3"],
      onFilter: (value: any, record: any) => {
        const findAssumptionRules = record.rules.find((rule: any) => {
          return rule.type === RULE_TYPE.ASSUMPTION;
        });
        if (value === "0") {
          return !findAssumptionRules;
        }
        if (value === "3") {
          return !!findAssumptionRules;
        }
        return true;
      },
    },
    {
      title: "Filter",
      dataIndex: "filterRule",
      width: 400,
      render: (rules: any, record: any) => {
        const { filterRuleModfify, filterRuleNotMandatory } = getFilterRules(
          record,
          roomsHotel,
          ruleAll,
          rulesData
        );
        return (
          <>
            <RuleList
              rules={filterRuleModfify}
              isMandatory={true}
              onClick={onClickUnUsedToken}
              isContextMenu={true}
              record={record}
            />
            <RuleList
              rules={filterRuleNotMandatory}
              isMandatory={false}
              onClick={onClickUnUsedToken}
              isContextMenu={true}
              record={record}
            />
          </>
        );
      },
    },
    {
      title: "Addional Tags",
      dataIndex: "rules",
      width: 300,
      render: (rules: any, record: any) => {
        const ruleModify = findRuleAssumption(ruleAll, rules);
        return (
          <RuleList
            rules={ruleModify}
            isMandatory={false}
            onClick={onClickUnUsedToken}
            isContextMenu={true}
            record={record}
          />
        );
      },
    },
    {
      title: "Unprocessed Tokens",
      dataIndex: "noneProccessedTokens",
      width: 300,
      render: (noneProccessedTokens: Array<string>, record: any) =>
        record.status !== STATUS_ROOM.UNMMAPED ? (
          noneProccessedTokens.map((token: string, index: number) => (
            <Button
              key={index}
              type="dashed"
              style={{ display: "inline-block" }}
              onClick={() => {
                onClickUnUsedToken({
                  input: [token],
                  referenceId: [],
                  type: RULE_TYPE.NORMAL,
                });
                dispatch(actionSetCurrentRecordActionRoom(record));
              }}
            >
              {token}
            </Button>
          ))
        ) : (
          <></>
        ),
      sorter: (room1: any, room2: any) => {
        return (
          room2["noneProccessedTokens"].length -
          room1["noneProccessedTokens"].length
        );
      },
    },
  ];

  const onClickUnUsedToken = (token: any) => {
    if (pendingRule.input.length && pendingRule.type !== token.type) {
      if (token.type === RULE_TYPE.NORMAL) {
        const inputRule = token.input.reduce(
          (acc: string, currentValue: string) => acc + currentValue,
          ""
        );
        setPendingRule((pendingRule) => ({
          ...pendingRule,
          input: token.input.length ? inputRule : "",
          type: token.type,
          referenceIds: [],
          assumption: false,
        }));
      }

      if (token.type === RULE_TYPE.ASSUMPTION) {
        const inputRule = token.input.reduce(
          (acc: string, currentValue: string) => acc + " " + currentValue,
          ""
        );
        const referenceIds = pendingRule.referenceIds.concat([
          token.referenceId,
        ]);
        setPendingRule((pendingRule) => ({
          ...pendingRule,
          input: token.input.length ? inputRule : "",
          type: token.type,
          referenceIds: referenceIds,
          assumption: true,
        }));
      }
    } else {
      if (token.type === RULE_TYPE.NORMAL) {
        const inputRule = token.input.reduce(
          (acc: string, currentValue: string) => acc + currentValue,
          ""
        );
        setPendingRule((pendingRule) => ({
          ...pendingRule,
          input: (pendingRule.input + " " + inputRule).trim(),
          type: token.type,
          referenceIds: [],
          assumption: false,
        }));
      }
      if (
        token.type === RULE_TYPE.ASSUMPTION &&
        !pendingRule.referenceIds.includes(token.referenceId)
      ) {
        const referenceIds = pendingRule.referenceIds.concat([
          token.referenceId,
        ]);
        const inputRule = token.input.reduce(
          (acc: string, currentValue: string) => acc + " " + currentValue,
          ""
        );
        setPendingRule((pendingRule) => ({
          ...pendingRule,
          input: (pendingRule.input + " " + inputRule).trim(),
          type: token.type,
          referenceIds: referenceIds,
          assumption: true,
        }));
      }
    }
  };

  const sortRulesFollowInputLengthThenTarget = (rules: Array<any>) => {
    return rules.length < 2
      ? rules
      : rules.sort((previousRule: any, currentRule: any) => {
          if (currentRule.input.length - previousRule.input.length === 0)
            return currentRule.input.target - currentRule.input.target;
          return currentRule.input.length - previousRule.input.length;
        });
  };

  const onBack = () => {
    props.sendWssMessage({
      action: "out",
      hotelId: hotelId,
    });
    navigate("/");
  };
  const onChangePage = (page: number) => {
    dispatch(actionSetCurrentPage(page));
  };

  useEffect(() => {
    const init = async () => {
      dispatch(getRoomsHotel(hotelId));
      dispatch(getTargetRoomsHotel(hotelId));
      dispatch(actionShowHotel(hotelId));
      dispatch(getRules({ hotelId }));
      props.sendWssMessage({
        action: "join",
        hotelId: hotelId,
      });
    };
    init();
  }, []);

  useEffect(() => {
    // set ruleAll
    let ruleAll: any[] = [];
    if (roomsHotel.length) {
      for (const room of roomsHotel) {
        const rule = (room as any).rules.length ? (room as any).rules : [];
        ruleAll = ruleAll.concat(rule);
      }
    }
    setRuleAll(ruleAll);
    // end set RuleAll
    const targetRoomsNotDeleted = targetRoomsHotel.filter(
      (room: { status: number }) => room.status !== 7
    );
    let rooms = cloneDeep(roomsHotel);
    rooms.forEach((room: any) => {
      let roomName = room.name;
      room.rules = sortRulesFollowInputLengthThenTarget(room.rules);
      room["noneProccessedTokens"] = getNoneProcessedTokens(
        room.rules,
        roomName
      );

      if (room.targetRoom && room.targetRoom.rules) {
        room["targetRoom"] = room.targetRoom.originalName;
        room["targetRoomMappingPercentage"] = 2;
      } else if (room.status === STATUS_ROOM.UNMMAPED) {
        room["targetRoom"] = <Tag color="red">UNMAPPED</Tag>;
        room["targetRoomMappingPercentage"] = 7;
      } else {
        const targetRoomAll = getRecommendTargetRooms(
          room,
          targetRoomsNotDeleted
        );
        const targetRoomRecommendations = targetRoomAll.slice(0, 8);
        let percentage = targetRoomRecommendations.length
          ? targetRoomRecommendations[0].percentage
          : 0;
        let invertPercentage = targetRoomRecommendations.length
          ? targetRoomRecommendations[0].invertPercentage
          : 0;
        room["targetRoomMappingPercentage"] = percentage;
        room["invertPercentage"] = invertPercentage;
        room["targetRoomAll"] = targetRoomAll;
        room["targetRoomRecommendations"] = targetRoomRecommendations;
        room["targetRoom"] = (
          <MatchTargetRoomRecommendation
            targetRooms={targetRoomRecommendations}
            percentage={percentage}
            invertPercentage={invertPercentage}
            children={
              <Tag>
                {(((percentage + invertPercentage) * 100) / 2).toFixed(2) +
                  "% "}
              </Tag>
            }
            roomName={room.name}
            roomTaggedRules={room.rules}
          />
        );
      }
    });
    rooms.forEach((room: any) => {
      room.rules = getSortedRulesFollowAlphaBetaWithCombinations(room.rules);
    });

    setData(rooms);
  }, [roomsHotel, targetRoomsHotel]);

  useEffect(() => {
    if (currentRecordActionRoom && currentRecordActionRoom.id && data.length) {
      scrollIntoView(
        document.querySelector(
          `[data-row-key="${currentRecordActionRoom.id}"]`
        ) as HTMLElement,
        {
          align: {
            top: 0,
          },
        }
      );
      dispatch(actionResetCurrentRecordActionRoom());
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (
      statusRunMapping ||
      deletedRule ||
      unmappedRoom ||
      revertUnMapRoom ||
      setTarget ||
      unsetTarget
    ) {
      setLoadingRoom(true);
      setTimeout(() => {
        dispatch(getRoomsHotel(hotelId));
        dispatch(getTargetRoomsHotel(hotelId));
        setLoadingRoom(false);
      }, 1000);
    }
    if (statusRunMapping) {
      dispatch(actionResetStatusRunMapping());
    }
    if (deletedRule) {
      dispatch(actionResetDeleteRule());
    }
    if (unmappedRoom) {
      dispatch(actionResetStatusUnMap());
    }
    if (revertUnMapRoom) {
      dispatch(actionResetStatusUnMap());
    }
    if (setTarget) {
      dispatch(actionResetSetTarget());
      setOpenListTargetRoom({
        ...popupContextMenu,
        visible: false,
      });
    }
    if (unsetTarget) {
      dispatch(actionResetUnSetTarget());
    }
  }, [
    deletedRule,
    statusRunMapping,
    unmappedRoom,
    revertUnMapRoom,
    setTarget,
    unsetTarget,
  ]);

  useEffect(() => {
    if (rules.length > 0) {
      const transformRulesData = rules.map((item: any) => ({
        dimension: item?.dimension,
        value: item?.roomNameEn,
        roomNameKr: item?.roomNameKr,
      }));
      setRulesData(transformRulesData);
    }
  }, [rules]);

  useEffect(() => {
    dispatch(
      updateExpediaRoomMappingByHotelId({
        hotelId: params.hotelId
          ? parseInt(params.hotelId)
          : RULE_TARGET.GENERAL_RULE,
      })
    );
  }, []);
  return (
    <div className="Home">
      <HeaderLayout
        title={`${hotelId}. ${hotelDetail.name} - Room Management`}
        onBack={onBack}
        extraFuncs={[
          <Button
            type="primary"
            key="3"
            shape="round"
            icon={<RetweetOutlined />}
            onClick={() => {
              window.location.reload();
            }}
          >
            F5
          </Button>,
          <a
            className="ant-btn"
            key="1"
            href={"/target-room-management/" + hotelId}
            target={"_blank"}
            rel="referrer noreferrer"
          >
            Target Rooms
          </a>,
          <a
            className="ant-btn"
            key="2"
            href={"/filter-management/" + hotelId}
            target={"_blank"}
            rel="referrer noreferrer"
          >
            Filter Management
          </a>,
        ]}
      >
        <CreateRuleFunctionMenu
          pendingRule={pendingRule}
          setPendingRule={setPendingRule}
          currentPage={currentPage}
        />
      </HeaderLayout>
      <Table
        loading={loadingRoom}
        rowKey={"id"}
        size="small"
        style={{ marginTop: "10px" }}
        rowClassName={(record) => getClassNameSetTarget(record)}
        columns={columns}
        dataSource={data}
        pagination={{
          defaultPageSize: LIMIT_PAGESIZE_DEFAULT,
          defaultCurrent: currentPage,
          current: currentPage,
          onChange: onChangePage,
        }}
        scroll={{ y: window.innerHeight - 300 }}
      />
      <RoomContext
        {...popupContextMenu}
        openListTargetRoomMapping={() => {
          setOpenListTargetRoom({
            ...popupContextMenu,
            visible: true,
          });
        }}
      />
      <ListTargetRoomWithRoom
        {...openListTargetRoom}
        closeModal={() => {
          setOpenListTargetRoom({
            ...popupContextMenu,
            visible: false,
          });
        }}
      />
    </div>
  );
}

export default RoomManagement;
