import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./index";
import request from "../util/request";
import { get } from "lodash";

export interface IParamsGetList {
  page: number;
  size: number;
  hotelId: number;
  hotelName: string;
  nation: string;
  cityId: string;
}

const initialState = {
  listHotel: {
    content: [],
    totalElements: 0,
  },
  listCity: [] as any[],
  loadingGetListHotel: false,
  filters: {
    nation: "",
    cityId: "",
    hotelId: 0,
    hotelName: "",
  },
  hotel: {} as any,
};

export const actionGetListHotel = createAsyncThunk(
  "hotel/actionGetListHotel",
  async (params: IParamsGetList, { rejectWithValue }) => {
    try {
      let queryParams = [];

      if (params.hotelId) queryParams.push(`hotelId=${params.hotelId}`);
      if (params.hotelName) queryParams.push(`hotelName=${params.hotelName}`);
      if (params.nation) queryParams.push(`nation=${params.nation}`);
      if (params.cityId) queryParams.push(`cityMasterId=${params.cityId}`);

      let URL = `/hotel?size=${params.size}&page=${params.page}`;

      if (queryParams.length > 0) {
        URL += `&${queryParams.join("&")}`;
      }

      const response = await request({
        url: URL,
        method: "GET",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionGetCity = createAsyncThunk(
  "hotel/actionGetCity",
  async (params: { nation: string }, { rejectWithValue }) => {
    try {
      let URL = `/city-mast/get-id-by-nation`;
      if (params.nation) URL += `?nationCode=${params.nation}`;
      const response = await request({
        url: URL,
        method: "GET",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionShowHotel = createAsyncThunk(
  "hotel/actionShowHotel",
  async (hotelId: number, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/hotel/${hotelId}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    setFilterHotel(state, action) {
      state.filters = {...action.payload};
    },
    resetListCity(state) {
      state.listCity = [];
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetListHotel.pending, (state) => {
        state.loadingGetListHotel = true;
      })
      .addCase(actionGetListHotel.fulfilled, (state, action) => {
        state.listHotel.content = get(action, "payload.data.content");
        state.listHotel.totalElements = get(
          action,
          "payload.data.totalElements"
        );
        state.loadingGetListHotel = false;
      })
      .addCase(actionGetListHotel.rejected, (state, action) => {
        state.loadingGetListHotel = false;
      })

      .addCase(actionGetCity.fulfilled, (state, action) => {
        const data = get(action, "payload.data");
        state.listCity = data.map((item: { cityNameKr: string; cityMasterId: number; }) => {
          return {
            label: item.cityNameKr,
            value: item.cityMasterId,
          };
        });
      })
      .addCase(actionGetCity.rejected, (state) => {
        state.listCity = [];
      })

      // SHOW HOTEL
      .addCase(actionShowHotel.fulfilled, (state, action) => {
        state.hotel = get(action, "payload.data");
      })
      .addCase(actionShowHotel.rejected, (state, action) => {
        state.hotel = {};
      });
  },
});

export const { setFilterHotel, resetListCity } = slice.actions;

export const selectedFilterHotel = (state: RootState) => state.hotel.filters;

export const selectedListHotel = (state: RootState) => state.hotel.listHotel;

export const selectedLoadingGetListHotel = (state: RootState) =>
  state.hotel.loadingGetListHotel;

export const selectedHotelDetail = (state: RootState) => state.hotel.hotel;
export const selectedListCity = (state: RootState) => state.hotel.listCity;

export default slice.reducer;
