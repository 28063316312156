import { Select, Button, Space, AutoComplete, Descriptions, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ClassifiedDimension from '../../../common/ClassifiedDimension';
import { DIMENSIONS, RULE_TARGET } from '../../../constants/ClassifiedDimensionConst';
import { commitMapping, selectedStatusIsProcessing, actionResetStatusRunMapping, selectedStatusRunMapping, actionGetStatusIsProcessing } from '../../../store/engineResultSlice';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { create, getRules, simulate } from '../../../store/ruleSlice';
import { get } from 'lodash';
import { RULE_TYPE } from '../../../constants/Rule';

interface IProps {
  pendingRule: any,
  setPendingRule: Function
  currentPage: number,
}

function CreateRuleFunctionMenu(props: IProps) {
  const navigate = useNavigate()
  let params = useParams();
  const dispatch = useAppDispatch();
  const hotelId = params.hotelId ? parseInt(params.hotelId) : RULE_TARGET.GENERAL_RULE;

  const pendingRule = props.pendingRule
  const [pendingRules, setPendingRules] = useState<Array<any>>([])
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const isProcessing = useAppSelector(selectedStatusIsProcessing);
  const statusRunMapping = useAppSelector(selectedStatusRunMapping);


  const onChangeDimension = (value: string) => {
    props.setPendingRule({ ...pendingRule, dimension: value })
  }
  const clearPendingRule = () => {
    props.setPendingRule({
      target: hotelId,
      input: "",
      value: "",
      dimension: "GRADE",
      assumption: false,
      type: RULE_TYPE.NORMAL,
      referenceIds: [],
    })
  }

  const getTextButtonSubmit = () => {
    if (isProcessing) {
      return "Show result"
    } else if (pendingRules.length === 0)
      return 'Just simulate'
    return 'Add rules'
  }


  const addAllRules = async () => {
    if (!isProcessing) {
      const confirmBox = window.confirm('Are you sure to run simulation?')

      if (confirmBox) {
        let res;
        if (pendingRules.length === 0)
          res = await simulate([hotelId], 'connectionId');
        else {
          const pendingRuleCustom = pendingRules.map(rule => {
            if (rule.assumption && rule.type  === RULE_TYPE.ASSUMPTION) {
              rule.input = rule.referenceIds.join('-');
            }
            return rule;
          })
          res = await create(pendingRuleCustom, hotelId, 'connectionId');
        }
        if (res.status === 200) {
          const batchJobs = get(res, 'data');
          if (batchJobs.length > 0) {
            if (batchJobs[0].startsWith('single'))
              navigate(`/mapping-result/${hotelId}`)
            else navigate(`/mapping-result/multiple`)
          }
        } else alert("error")
      }
    } else {
      navigate(`/mapping-result/${hotelId}`)
    }
  }

  const addNewRuleToPendingList = () => {
    if (pendingRule.input.length === 0) {
      alert('Cannot add empty input rule!')
      return;
    }
    if (pendingRule.value.length === 0 && pendingRule.dimension !== 'IGNORE') {
      alert('Cannot add empty rule!')
      return;
    }
    setPendingRules(pendingRules => [...pendingRules, pendingRule])
    clearPendingRule()
  }

  function runMapping() {
    const confirmBox = window.confirm('Are you sure to run mapping?');
    if (confirmBox) {
      dispatch(commitMapping(hotelId));
    }
  }

  /** Autocomplete input **/
  useEffect(() => {
    const search = async () => {
      if (pendingRule.input.length > 0) {
        const rules = await getRules(0, 0, undefined, pendingRule.input);
        const suggestions = rules.data.content.sort((rule1: any, rule2: any) => rule2.score - rule1.score).map((rule: any) => {
          return {
            value: rule.dimension + '_' + rule.value
          }
        })
        setOptions(suggestions)
        return;
      }

      setOptions([]);
    }
    search()
  }, [pendingRule.input]);

  useEffect(() => {
    dispatch(actionGetStatusIsProcessing(hotelId));
  }, [])

  useEffect(() => {
    if (statusRunMapping) {
      dispatch(actionGetStatusIsProcessing(hotelId));
      dispatch(actionResetStatusRunMapping());
    }

  }, [statusRunMapping]);

  const onSelect = (data: string) => {
    setOptions([])
    props.setPendingRule({ ...pendingRule, dimension: data.split('_')[0], value: data.substring(data.indexOf('_') + 1) })
  };

  const onChange = (data: string) => {
    data = data.replace(" ", "").toUpperCase()
    props.setPendingRule({ ...pendingRule, value: data })
  }

  const onDeletePendingRule = (target: number, dimension: string, value: string) => {
    const rules = pendingRules.filter((pendingRule: any) => target + dimension + value !== pendingRule.target + pendingRule.dimension + pendingRule.value)
    setPendingRules(rules)
  }

  function checkAssumption(e: CheckboxChangeEvent) {
    props.setPendingRule({ ...pendingRule, assumption: e.target.checked })
  }

  return (
    <div>
      <Descriptions size="small" column={3}>
        <Descriptions.Item>
          <Space>
            <Select style={{ width: 150 }} value={pendingRule.dimension} onChange={onChangeDimension}>
              {
                DIMENSIONS.map((dimension: string) => <Select.Option key={dimension} value={dimension}>{dimension}</Select.Option>)
              }
            </Select>

            <div hidden={pendingRule.dimension === 'IGNORE'}>
              <AutoComplete
                open={true}
                value={pendingRule.value}
                options={options}
                style={{ width: 200 }}
                onSelect={onSelect}
                onChange={onChange}
                placeholder="Value for rule"
              />
            </div>

            <Checkbox style={{ width: 120 }} checked={pendingRule.assumption || pendingRule.type  === RULE_TYPE.ASSUMPTION} disabled={pendingRule.type  === RULE_TYPE.ASSUMPTION} onChange={checkAssumption}>Assumption</Checkbox>
            <Button onClick={addNewRuleToPendingList}>Add Pending Rule</Button>
            <Button danger onClick={clearPendingRule}>Clear</Button>
            <ClassifiedDimension dimension={pendingRule.dimension} target={pendingRule.target} value={pendingRule.value} input={pendingRule.input} assumption={pendingRule.assumption} />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item style={{ float: 'right' }}>
          <Space>
            <Button type="primary" size="large" onClick={addAllRules}>{getTextButtonSubmit()}</Button>
            <Button onClick={runMapping} size="large" style={{ backgroundColor: "green", borderColor: "green", color: "#fff" }}>Run Mapping</Button>
          </Space>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions size="small" column={1}>
        <Descriptions.Item>
          {
            pendingRules.map((rule) => {
              return <ClassifiedDimension delete={true} onDelete={onDeletePendingRule} key={rule.target + rule.dimension + rule.value} dimension={rule.dimension} target={rule.target} value={rule.value} input={rule.input} assumption={rule.assumption} />
            })
          }
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default CreateRuleFunctionMenu;
