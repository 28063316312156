import { useState, useEffect } from 'react';
import { PageHeader, Popover, Table, Tooltip } from 'antd';
import RuleList from './RuleList';
import { InfoCircleTwoTone } from '@ant-design/icons';
import {renderTooltipMatchingRatio} from "../../../helper/RoomHelper";

interface IProps {
  targetRooms: Array<any>,
  percentage: number,
  invertPercentage: number,
  roomName: string,
  roomTaggedRules: Array<any>
  children: React.ReactNode
}

function MatchTargetRoomRecommendation(props: IProps) {
  const columns = [
    {
      title: 'Target Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Matching ratio',
      dataIndex: 'percentage',
      key: 'percentage',

      render: (rules: any, record: any) => (
        <p>
          {((record.percentage + record.invertPercentage) * 100 / 2).toFixed(2) + '% '}
          <Tooltip title={renderTooltipMatchingRatio(record)}><InfoCircleTwoTone /></Tooltip>
        </p>
      )
    },
    {
      title: 'Mapped',
      dataIndex: 'mappedRules',
      key: 'mappedRules',
      render: (rules: any, record: any) => (
        <RuleList rules={rules} isMandatory={true} onClick={() => { }} />
      )
    },
    {
      title: 'Upmapped',
      dataIndex: 'unMappedRules',
      key: 'unMappedRules',
      render: (rules: any, record: any) => (
        <RuleList rules={rules} isMandatory={true} onClick={() => { }} />
      )
    },
    {
      title: 'Additional Info',
      dataIndex: 'additionalRules',
      key: 'additionalRules',
      render: (rules: any, record: any) => (
        <RuleList rules={rules} isMandatory={false} onClick={() => { }} />
      )
    }
  ];

  const [data, setData] = useState(<Table dataSource={[]} columns={columns} pagination={false} />)
  useEffect(() => {
    const targetRooms = props.targetRooms

    setData(
      <>
        <PageHeader
          className="site-page-header"
          title={props.roomName}
          subTitle={<><RuleList rules={props.roomTaggedRules} isMandatory={true} onClick={() => { }} />
            <RuleList rules={props.roomTaggedRules} isMandatory={false} onClick={() => { }} /></>}
        />
        <Table rowClassName={(record) => {
          const maxMappedRulesLength = props.targetRooms.length > 0 ? props.targetRooms[0].mappedRules.length : 0
          if (maxMappedRulesLength === record.mappedRules.length && record.percentage === props.percentage && props.percentage > 0) {
            return 'table-row-1'
          }
          return 'table-row-light'
        }} dataSource={targetRooms} columns={columns} pagination={false} />
      </>
    )
  }, [props])

  return (
    <Popover content={data} placement="right">
      { props.children }
    </Popover>
  );
}

export default MatchTargetRoomRecommendation;
