import { RootState } from ".";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../util/request";

interface IExpediaRoomInfoParams {
  roomId: number;
  language: number[];
}

interface IExpediaTargetRoomIdMapping {
  id: number;
  roomInfoId: number | null;
}
interface IExpediaRoomInfoState {
  data: any[];
  loading: boolean;
  expediaMappingRoomId: string;
}

const initialState: IExpediaRoomInfoState = {
  data: [],
  loading: false,
  expediaMappingRoomId: "NONE",
};

export const getExpediaRoomInfo = createAsyncThunk(
  "expedia-room-info",
  async (params: IExpediaRoomInfoParams, { rejectWithValue }) => {
    try {
      const arrGetExpediaRoomsInfo = [];
      for (const language of params.language) {
        arrGetExpediaRoomsInfo.push(
          request({
            url: `/room-info/${params.roomId}?language=${language}`,
            method: "GET",
          })
        );
      }
      return await Promise.all(arrGetExpediaRoomsInfo);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setExpediaTargetRoomMappingRoomId = createAsyncThunk(
  "expedia-targetRoom-mapping",
  async (params: IExpediaTargetRoomIdMapping, { rejectWithValue }) => {
    try {
      return await request({
        url: `/target-room/room-info`,
        method: "PUT",
        data: params,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateExpediaRoomMappingByHotelId = createAsyncThunk(
  "expedia-targetRoom-mapping-update",
  async (params: { hotelId: number }, { rejectWithValue }) => {
    try {
      return await request({
        url: `room-info/update-hotel-info`,
        method: "PUT",
        params,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "expedia-room",
  initialState,
  reducers: {
    actionResetExpediaRoomInfo(state) {
      state.data = [];
    },
    actionResetExpediaMappingRoomId(state) {
      state.expediaMappingRoomId = "NONE";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpediaRoomInfo.fulfilled, (state, action) => {
        let newData: any[] = [];
        for (const resExpediaRoomInfo of action.payload) {
          newData = [...newData, resExpediaRoomInfo.data];
        }
        state.data = newData;
        state.loading = false;
      })
      .addCase(getExpediaRoomInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExpediaRoomInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setExpediaTargetRoomMappingRoomId.fulfilled, (state) => {
        state.expediaMappingRoomId = "SUCCESS";
      })
      .addCase(setExpediaTargetRoomMappingRoomId.rejected, (state) => {
        state.expediaMappingRoomId = "ERROR";
      });
  },
});

export const { actionResetExpediaRoomInfo, actionResetExpediaMappingRoomId } =
  slice.actions;
export const selectStateExpediaRoomInfo = (state: RootState) =>
  state.roomMappingInExpedia;

export default slice.reducer;
