import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./index";
import request from "../util/request";
import { get } from "lodash";

const initialState = {
  statusRunMapping: false,
  statusIsProcessing: false,
};

export const getChanges = async(hotelId: string) => {
  const response = await request({
    url: `/engine-result/${hotelId}`,
    method: "GET",
  });
  return response;
}

export const getCurrentMultipleHotelIds = async () => {
  const response = await request({
    url: `/engine-result/hotel-ids-current-multiple-processing/`,
    method: "GET",
  });
  return response;
}

export const commitMapping = createAsyncThunk(
  "engineResult/commitMapping",
  async (hotelId: string | number, { rejectWithValue }) => {
    try {
      let hotelChangeId = '';
      if(hotelId !== 'multiple') {
        hotelChangeId = 'single_' + hotelId;
      } else {
        hotelChangeId = 'multiple';
      }
      const response = await request({
        url: `/engine-result/commit-mapping/${hotelChangeId}`,
        method: "POST",
      });
      return response; 
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionGetStatusIsProcessing = createAsyncThunk(
  "engineResult/actionGetStatusIsProcessing",
  async (hotelId: string | number, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/engine-result/is-processing/${hotelId}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "engineResult",
  initialState,
  reducers: {
    actionResetStatusRunMapping(state) {
      state.statusRunMapping = false;
    },
  },

  extraReducers: (builder) => {
    builder
    .addCase(commitMapping.fulfilled, (state) => {
      state.statusRunMapping = true;
    }) 
    .addCase(commitMapping.rejected, (state) => {
      state.statusRunMapping = false;
    })
    .addCase(actionGetStatusIsProcessing.fulfilled, (state, action) => {
      state.statusIsProcessing = get(action, 'payload.data');
    }) 
    .addCase(actionGetStatusIsProcessing.rejected, (state, action) => {
      state.statusIsProcessing = false;
    });
  },
});

export const { actionResetStatusRunMapping } = slice.actions;

export const selectedStatusRunMapping = (state: RootState) => state.engineResult.statusRunMapping;

export const selectedStatusIsProcessing = (state: RootState) => state.engineResult.statusIsProcessing;

export default slice.reducer;
