import { Form, Input, Modal } from "antd";
import { useEffect } from "react";
import { useAppDispatch }  from '../../store/index';
import { actionResetIsCloneTargetRoom, createTargetRoom } from '../../store/targetRoomSlice';
interface IProps {
  show: boolean,
  targetRoomAdd: any
}
function CreateTargetRoom(props: IProps) {
  const { show, targetRoomAdd } = props;
  const dispatch = useAppDispatch();
  const [formCreate] = Form.useForm();
  function handleOk() {
    formCreate.submit();
  };

  function handleCancel() {
    dispatch(actionResetIsCloneTargetRoom());
  };

  useEffect(() => {
    if(!show) formCreate.resetFields();
    if (show) {
      formCreate.setFieldValue('originalName', targetRoomAdd.originalName);
    } 
  }, [formCreate, show, targetRoomAdd.originalName]);

  function addTargetRoom() {
    const { originalName } = formCreate.getFieldsValue();
    dispatch(createTargetRoom({
      originalName: originalName,
      hotelId: targetRoomAdd.hotel.id,
    }));
  }
  return (
    <Modal title="New Target Room" open={show} onOk={handleOk}
      onCancel={handleCancel}>
      <Form
        form={formCreate}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={addTargetRoom}
      >
        <Form.Item
          label="Target Room Name"
          name="originalName"
          rules={[{ required: true, message: 'Please input your Target Room Name!' }]}
          initialValue={targetRoomAdd.originalName}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateTargetRoom;