import { ReactNode, useEffect } from "react";
import { PageHeader } from 'antd';
import { HomeOutlined } from "@ant-design/icons";

// Layout
const HeaderLayout = ({ children, title, subtitle, onBack, showBack = true, extraFuncs }: { children: ReactNode, title: string, subtitle?: string, onBack?: Function, showBack?: boolean, extraFuncs?: Array<ReactNode> }) => {
    useEffect(() => {
        document.title = title
    }, [title])
    return (
        <div className="site-page-header-ghost-wrapper">
            <PageHeader
                className="site-page-header"
                backIcon={<HomeOutlined />}
                onBack={showBack ? () => {
                    if (onBack) onBack()
                } : undefined}
                title={title}
                subTitle={subtitle ?? ''}
                extra={extraFuncs ?? []}
            >
                {children}
            </PageHeader>
        </div>
    )
};

export default HeaderLayout