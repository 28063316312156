import { notification } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';
import URL from '../constants/URL';
import { defaultTo, get } from 'lodash';
import { store } from '../store/index';
import { actionLogout } from '../store/authSlice';

export const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

instanceAxios.defaults.headers.common['Content-Type'] = 'application/json';

instanceAxios.interceptors.response.use(
  (response) => {
    if (response.data.status && +response.data.status !== 0) {
      const message = defaultTo(get(response, 'data.message'), 'Error');
      notification.error({ message });
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    if (!axios.isCancel(error)) {
      const message = defaultTo(get(error, 'message'), 'Error');
      notification.error({ message });
    }

    const { url } = error.config;
    const isUrlLogin = url === URL.Login;
    if ([401, 403].includes(error.response.status) && !isUrlLogin) {
      store.dispatch(actionLogout());
    }
    return Promise.reject(error);
  }
);

export default function request(options: AxiosRequestConfig) {
  return instanceAxios(options);
}
