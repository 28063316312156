import { useState, useEffect } from 'react';
import { Table } from 'antd';
import ClassifiedDimension from '../../../common/ClassifiedDimension';
import { getChanges } from '../../../store/engineResultSlice';
import { getRulesByIds } from '../../../store/ruleSlice';
import { actionGetRoomsByIds } from '../../../store/roomSlice';
import { getTargetRoomsByIds } from '../../../store/targetRoomSlice';
import {findRuleAssumption} from "../../../helper/RoomHelper";

interface IProps {
  loadingRoom: boolean,
  setLoadingRoom: any,
  hotelId: string | undefined
}

function MappingResultMainData(props: IProps) {
  const { loadingRoom, setLoadingRoom } = props;
  const [data, setData] = useState([]);
  const [dataTargetRooms, setDataTargetRooms] = useState([]);
  const [allRules, setAllRules] = useState<any>([]);

  const columnsTargetRoomResult = [
    {
      title: 'Id',
      dataIndex: 'rid',
    },
    {
      title: 'Room Name',
      dataIndex: 'name',
    },
    {
      title: 'Current result',
      dataIndex: 'ruleUIs',
    },
    {
      title: 'Deleted Tags',
      dataIndex: 'deletedTags',
    },
    {
      title: 'Added Tags',
      dataIndex: 'addedTags',
      sorter: (a: any, b: any) => {
        if (a.a && b.a)
          return a.a.length - b.a.length
        else if (a.a) return a.a.length
        else if (b.a) return -b.a.length
        return 0
      }
    }
  ]

  const columnsResult = [
    {
      title: 'Id',
      dataIndex: 'rid',
    },
    {
      title: 'Room Name',
      dataIndex: 'name',
    },
    {
      title: 'Current Target',
      dataIndex: 'currentTargetRoom',
    },
    {
      title: 'New Target',
      dataIndex: 'newTargetRoom',
    },
    {
      title: 'Current result',
      dataIndex: 'ruleUIs',
    },
    {
      title: 'Deleted Tags',
      dataIndex: 'deletedTags',
    },
    {
      title: 'Added Tags',
      dataIndex: 'addedTags',
      sorter: (a: any, b: any) => {
        if (a.a && b.a)
          return a.a.length - b.a.length
        else if (a.a) return a.a.length
        else if (b.a) return -b.a.length
        return 0
      }
    }
  ]

  const getChangedRuleIds = (changes: Array<any>) => {
    const changeRuleIds = new Set()
    changes.forEach((change: any) => {
      if (change.a && change.a.length > 0)
        change.a.forEach((ruleId: number) => {
          changeRuleIds.add(ruleId)
        });

      if (change.d && change.d.length > 0)
        change.d.forEach((ruleId: number) => {
          changeRuleIds.add(ruleId)
        });
    })
    return changeRuleIds
  }

  const handleResultJob = async (hotelId: string | undefined) => {
    if (!hotelId) return false
    else {
      const response = await getChanges(hotelId);
      if (data) {
        const changes: any = response.data.rooms?.changes
        if(changes) {
          const {rooms, allRules}: any = await checkMetaDataThenAddIfNotExisted(Object.values(changes), 1, changes.length)
          setData(rooms)
          handleResultTargetRoomJob(response.data.targetRooms?.changes, allRules)
          return true
        }
      }
      return false
    }
  }

  const handleResultTargetRoomJob = async (changes: any, allRules: any ) => {
    const rooms: any = await visualizeTargetRooms(changes, allRules)
    setDataTargetRooms(rooms)
  }

  const visualizeTargetRooms = async (changes: any, allRules: any) => {
    const roomChanges = Object.values(changes)
    const changedRuleIds = getChangedRuleIds(roomChanges)
    const changedRules = (await getRulesByIds(changedRuleIds)).data;
    const rooms = (await getTargetRoomsByIds(changes.map((room: any) => room.rid))).data;
    roomChanges.forEach((roomChange: any) => {
      const room = rooms.find((room: any) => room.id === roomChange.rid);

      const rulesAdded = roomChange.a ? roomChange.a.map((ruleId: number) => {
        const rule = changedRules.find((ruleItem: any) => ruleItem.id === ruleId) ? findRuleAssumption(allRules, [changedRules.find((ruleItem: any) => ruleItem.id === ruleId)])[0] : null;
        return ClassifiedDimension({ key: ruleId, type: rule.type, nameRuleAssumption: rule.nameRuleAssumption, dimension: rule.dimension, target: rule.target, input: rule.input, value: rule.value, assumption: rule.assumption })
      }) : null;

      const rulesDeleted = roomChange.d ? roomChange.d.map((ruleId: number) => {
        const rule = changedRules.find((ruleItem: any) => ruleItem.id === ruleId) ? findRuleAssumption(allRules, [changedRules.find((ruleItem: any) => ruleItem.id === ruleId)])[0] : null;
        return ClassifiedDimension({ key: ruleId, type: rule.type, nameRuleAssumption: rule.nameRuleAssumption, dimension: rule.dimension, target: rule.target, input: rule.input, value: rule.value, assumption: rule.assumption })
      }) : null;

      roomChange['key'] = room['id']
      roomChange['name'] = room['originalName']
      roomChange['addedTags'] = rulesAdded;
      const ruleUIs = findRuleAssumption(allRules, room.rules);
      roomChange['ruleUIs'] = ruleUIs.map((rule: any, index: number) => {
        return ClassifiedDimension({ key: index, type: rule.type, nameRuleAssumption: rule.nameRuleAssumption, dimension: rule.dimension, target: rule.target, input: rule.input, value: rule.value, assumption: rule.assumption })
      });
      roomChange['deletedTags'] = rulesDeleted;
    })

    return roomChanges
  }

  const getRoomByIdsHelper = async (roomIds: string[]) => {
    let promiseArray: any[] = [];
    const chunkSize = 80;
    for (let i = 0; i < roomIds.length; i += chunkSize) {
      const chunk = roomIds.slice(i, i + chunkSize);
      promiseArray.push(actionGetRoomsByIds(chunk));
    }
    return (await Promise.all(promiseArray)).reduce((acc, item) => { return acc.concat(item.data); }, []);
  }

  const checkMetaDataThenAddIfNotExisted = async (roomChanges: Array<any>, currentPage: number | undefined, pageSize: number | undefined) => {
    if (currentPage === undefined || pageSize === undefined)
      return data
    const start = (currentPage - 1) * pageSize
    const end = currentPage * pageSize > roomChanges.length ? roomChanges.length : currentPage * pageSize;
    
    const changeRooms = roomChanges.slice(start, end);

    const changeRoomIds = changeRooms.map(room => room.rid);
    const changeTargetRoomIds = new Set(changeRooms.map(room => room.tid).filter(targetRoomId => targetRoomId !== 0));
    const changedRuleIds = getChangedRuleIds(changeRooms)
    let changedRules: any[];
    let roomsCurrentPage;
    let newTargetRoomsCurrentPage;
    let allRules: any[] = [];

    for (let i = start; i < end; i++) {
      const roomChange = roomChanges[i]
      if (!roomChange.hasOwnProperty('name')) {
        if (roomsCurrentPage === undefined || roomsCurrentPage === null) {
          changedRules = (await getRulesByIds(changedRuleIds)).data;
          roomsCurrentPage = await getRoomByIdsHelper(changeRoomIds);
          newTargetRoomsCurrentPage = (await getTargetRoomsByIds(changeTargetRoomIds)).data;
          if (roomsCurrentPage.length) {
            for (const room of roomsCurrentPage) {
              const rule = (room as any).rules.length ? (room as any).rules : [];
              allRules = allRules.concat(rule);
            }
          }
          if (newTargetRoomsCurrentPage.length) {
            for (const room of newTargetRoomsCurrentPage) {
              const rule = (room as any).rules.length ? (room as any).rules : [];
              allRules = allRules.concat(rule);
            }
          }
          setAllRules(allRules);
        }
        const room = roomsCurrentPage.find((room: any) => room.id === roomChange.rid);
        const rulesAdded = roomChange.a ? roomChange.a.map((ruleId: number) => {
          const rule = changedRules.find((ruleItem: any) => ruleItem.id === ruleId) ? findRuleAssumption(allRules, [changedRules.find((ruleItem: any) => ruleItem.id === ruleId)])[0] : null;
          return ClassifiedDimension({ key: ruleId, type: rule.type, nameRuleAssumption: rule.nameRuleAssumption, dimension: rule.dimension, target: rule.target, input: rule.input, value: rule.value, assumption: rule.assumption })
        }) : null;

        const rulesDeleted = roomChange.d ? roomChange.d.map((ruleId: number) => {
          const rule = changedRules.find((ruleItem: any) => ruleItem.id === ruleId) ? findRuleAssumption(allRules, [changedRules.find((ruleItem: any) => ruleItem.id === ruleId)])[0] : null;
          return ClassifiedDimension({ key: ruleId, type: rule.type, nameRuleAssumption: rule.nameRuleAssumption, dimension: rule.dimension, target: rule.target, input: rule.input, value: rule.value, assumption: rule.assumption })
        }) : null;

        roomChange['key'] = room['id']
        roomChange['name'] = room['originalName']
        roomChange['addedTags'] = rulesAdded;
        const ruleUIs = findRuleAssumption(allRules, room.rules);
        roomChange['ruleUIs'] = ruleUIs.map((rule: any, index: number) => {
          return ClassifiedDimension({ key: index, type: rule.type, nameRuleAssumption: rule.nameRuleAssumption, dimension: rule.dimension, target: rule.target, input: rule.input, value: rule.value, assumption: rule.assumption })
        });
        roomChange['deletedTags'] = rulesDeleted;
        if (room.targetRoom) {
          const currentTargetRoom = findRuleAssumption(allRules, room.targetRoom?.rules);
          roomChange['currentTargetRoom'] = currentTargetRoom.map((rule: any, index: number) => {
            return ClassifiedDimension({ key: index, type: rule.type, nameRuleAssumption: rule.nameRuleAssumption, dimension: rule.dimension, target: rule.target, input: rule.input, value: rule.value, assumption: rule.assumption })
          });
        }

        if (roomChange.tid !== 0) {
          const targetRoom = newTargetRoomsCurrentPage.find((targetRoom: any) => targetRoom.id === roomChange.tid)
          if (targetRoom) {
            const newTargetRoom = findRuleAssumption(allRules, targetRoom?.rules);
            roomChange['newTargetRoom'] = newTargetRoom.map((rule: any, index: number) => {
              return ClassifiedDimension({ key: index, type: rule.type, nameRuleAssumption: rule.nameRuleAssumption, dimension: rule.dimension, target: rule.target, input: rule.input, value: rule.value, assumption: rule.assumption })
            });
          }

        }
      }
    } 
    return {
      rooms: roomChanges.length > 0 ? roomChanges.filter((room: any) => {
        return room.addedTags || room.deletedTags
      }) : [],
      allRules: allRules
    };
  }
  useEffect(() => {
    const loadData = async () => {
      if (props.hotelId !== '0') {
        if (await handleResultJob(props.hotelId)) {
          setLoadingRoom(false)
        } else {
          const timer = setInterval(async () => {
            if (props.hotelId !== '0') {
              if (await handleResultJob(props.hotelId)) {
                setLoadingRoom(false)
                clearTimeout(timer)
              }
            }
          }, 5000);
        }
      }
    }
    loadData()
  }, [props.hotelId])

  return (
    <div>
      <h2>Target Rooms</h2>
      <Table pagination={{ pageSize: 5 }} loading={loadingRoom} columns={columnsTargetRoomResult as any} dataSource={dataTargetRooms} />
      <h2>Rooms</h2>
      <Table pagination={{ pageSize: 50 }} loading={loadingRoom} columns={columnsResult as any} dataSource={data} />
    </div>
  );
}

export default MappingResultMainData;
