import URL from '../constants/URL';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../store';
import { selectIsLogin, selectToken } from '../store/authSlice';
import { instanceAxios } from '../util/request';

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  const isLogin = useAppSelector(selectIsLogin);
  const token = useAppSelector(selectToken);

  if (token) instanceAxios.defaults.headers.common.Authorization = `Bearer ${token}`;

  if (isLogin) return children;

  return <Navigate to={URL.Login} replace />;
}
