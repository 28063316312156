import ConfigManagement from 'crud-with-file-import'
import { insertTargetRoom }  from '../../store/roomSlice'
import { useNavigate } from 'react-router-dom';

const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'ID',
    editable: false
  },
  {
    title: 'Hotel Name',
    dataIndex: 'Hotel Name',
    editable: true
  },
  {
    title: 'Hotel id, hotel name, hotel link',
    dataIndex: 'Hotel id, hotel name, hotel link',
    editable: true,
  },
  {
    title: 'Grade',
    dataIndex: 'Grade',
    editable: true
  },
  {
    title: 'Bed',
    dataIndex: 'Bed',
    editable: true,
  },
  {
    title: 'View',
    dataIndex: 'View',
    editable: true
  },
  {
    title: 'Add',
    dataIndex: 'Add',
    editable: true,
  },
  {
    title: 'Note',
    dataIndex: 'Note',
    editable: true,
  }
]

function InsertTargetRoom() {
  const navigate = useNavigate();
  const fillMissingDataToRecord = (rooms:any) => {
    for(let i = 0; i < rooms.length; i++) {
      if (!rooms[i]['ID']) {
        rooms[i]['ID'] = rooms[i - 1]['ID']
      }
      if (!rooms[i]['Hotel Name']) {
        rooms[i]['Hotel Name'] = rooms[i - 1]['Hotel Name']
      }
    }
  }
  
  const convertToDataImport = (rooms:any) => {
    fillMissingDataToRecord(rooms)
    let rawResult = []
    for (let room of rooms) {
      let listTargetRooms = []
      let grades = []
      let beds = []
      let views= []
      if (room['Grade']) {
        grades = room['Grade'].split(' or ')
      }
      if (room['Bed']) {
        beds = room['Bed'].split(' or ')
      }
      if (room['View']) {
        views = room['View'].split(' or ')
      }
  
      if(grades.length > 0) {
        for (let grade of grades) {
          let targetRoom = ''
          targetRoom += grade
  
          if(beds.length > 0) {
            for (let bed of beds) {
              let targetRoomHasBed = targetRoom + ' ' + bed
              if (views.length > 0) {
                for (let view of views) {
                  let targetRoomHasView = targetRoomHasBed + ' ' + view
                  listTargetRooms.push(targetRoomHasView)
                }
              } else {
                listTargetRooms.push(targetRoomHasBed)
              }
            }
          } else {
            if (views.length > 0) {
              for (let view of views) {
                let targetRoomHasView = targetRoom + ' ' + view
                listTargetRooms.push(targetRoomHasView)
              }
            } else {
              listTargetRooms.push(targetRoom)
            }
          }
        }
      } else {
        let targetRoom = ''
        if(beds.length > 0) {
          for (let bed of beds) {
            let targetRoomHasBed = targetRoom + bed
  
            if (views.length > 0) {
              for (let view of views) {
                let targetRoomHasView = targetRoomHasBed + ' ' + view
                listTargetRooms.push(targetRoomHasView)
              }
            } else {
              listTargetRooms.push(targetRoomHasBed)
            }
          }
        } else {
          if (views.length > 0) {
            for (let view of views) {
              let targetRoomHasView = targetRoom + view
              listTargetRooms.push(targetRoomHasView)
            }
          } else {
            listTargetRooms.push(targetRoom)
          }
        }
      }
  
      let results = []
  
      for (let targetRoom of listTargetRooms) {
        let result:any = {}
        result['original_name'] = targetRoom
        if (room['ID']) {
          result['hotel_id'] = room['ID']
        }
  
        if (room['Hotel Name']) {
          result['hotel_name'] = room['Hotel Name']
        }
        results.push(result)
      }
  
      rawResult.push(results)
    }
    rawResult = toApiData(rawResult).filter((item:any) => item['original_name'])
    rawResult = rawResult.reduce((group:any, item:any) => {
      const { hotel_id } = item;
      group[hotel_id] = group[hotel_id] ?? [];
      group[hotel_id].push(item);
      return group;
    }, {});
    const finalResult = []
    for(let hotel_id in rawResult) {
      const originalName = rawResult[hotel_id].map((item:any) => item.original_name)
      finalResult.push({
        hotelId: Number.parseInt(hotel_id),
        targetRooms: originalName
      })
    }
    return { hotelTargetRooms: finalResult }
  }

  function toApiData(newRooms:any) {
    const nextNewRooms = newRooms.flat()
    const listRoom = [];
    for (let newRoom of nextNewRooms) {
      const original_name = newRoom['original_name'].replaceAll("\\r\\n", "")
      const hotel_id = Number.parseInt(newRoom['hotel_id'])
      listRoom.push({
          original_name,
          hotel_id
    })
    }
    return nextNewRooms
  }

  async function handleCreate(param:any) {
    return await insertTargetRoom(param)
  }

  function postHandleCreate(result: any) {
    navigate(`/target-room-management/insertTargetRoom`)
  }

  return (
    <div className="Home">
      <ConfigManagement columns={COLUMNS}
      preHandleCreate={convertToDataImport}
      key='id'
      apiHost='http://roommapping-prod-coupang.ap-northeast-2.elasticbeanstalk.com/'
      apiCreatePath='target-room/multiple'
      postHandleCreate={postHandleCreate}
      handleCreate={handleCreate}
      importMode={'w'}
      index={''}
      />
    </div>
  );
}

export default InsertTargetRoom;