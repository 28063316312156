import vn from '../image/vn.png';
import th from '../image/thailand.png';
import kr from '../image/korea.png';
import jp from '../image/japan.png';
import gu from '../image/guam.png';
import ph from '../image/ph.png';
import us from '../image/us.png';
import mx from '../image/mx.png';
import sg from '../image/sg.png';

export const ERROR_MESSAGE = 'An error has occurred';
export const LIMIT_PAGESIZE_DEFAULT = 100;
export const ID_TARGET_ROOM_NOT_MAPPED = 99999;
export const NATIONS = [
    {
        value: 'kr',
        label: 'Korea',
    },
    {
        value: 'vn',
        label: 'VietNamese',
    },
    {
        value: 'jp',
        label: 'Japanese'
    },
    {
        value: 'th',
        label: 'Thailand',
    },
    {
        value: 'gu',
        label: 'Guam',
    },
    {
        value: 'us',
        label: 'USA',
    },
    {
        value: 'sg',
        label: 'Singapore',
    },
    {
        value: 'ph',
        label: 'Philippine',
    },
    {
        value: 'mx',
        label: 'Mexico',
    },
]

export const CITYS = [
    {
        value: 1,
        label: '바라코아',
    },
    {
        value: 2,
        label: '홀긴',
    },
    {
        value: 3,
        label: '만쟈니요'
    },
    {
        value: 4,
        label: '라스 투나스(쿠바)',
    },
    {
        value: 5,
        label: '관타나모',
    },
    {
        value: 6,
        label: '마탄사스',
    },
    {
        value: 7,
        label: '산타클라라 (쿠바)',
    },
    {
        value: 8,
        label: '카요 레비사 섬',
    },
    {
        value: 9,
        label: '산티아고 데 쿠바',
    },
]

const COUNTRY_IMG_SRC: { [key: string]: string } = {
    'vn': vn,
    'th': th,
    'kr': kr,
    'jp': jp,
    'gu': gu,
    'ph': ph,
    'us': us,
    'mx': mx,
    'sg': sg
}

export const getNation = (nation: string) => {
    return COUNTRY_IMG_SRC[nation];
}