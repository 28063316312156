import { cloneDeep } from "lodash";
import {
  DIMENSIONS_SCORE,
  MANDATORY_DIMENSIONS,
} from "../constants/ClassifiedDimensionConst";
import { findRuleAssumption } from "./RoomHelper";

const sortFollowAlphaBeta = (combinations: Array<any>) => {
  return combinations.sort((previous: any, current: any) => {
    return compareTwoRule(previous, current);
  });
};

export const compareTwoRule = (previous: any, current: any) => {
  if (
    DIMENSIONS_SCORE[previous.dimension] !== DIMENSIONS_SCORE[current.dimension]
  )
    return (
      DIMENSIONS_SCORE[current.dimension] - DIMENSIONS_SCORE[previous.dimension]
    );
  else if (previous.dimension !== current.dimension)
    return previous.dimension.localeCompare(current.dimension);
  return previous.value.localeCompare(current.value);
};

export const getSortedRulesFollowAlphaBetaWithCombinations = (
  rules: Array<any>
) => {
  rules.forEach((rule) => (rule.combinations = undefined));

  const returnRules =
    rules.length < 2
      ? rules
      : rules.reduce((previousResult: any, currentRule: any) => {
          const lastRule = Array.isArray(previousResult)
            ? previousResult[previousResult.length - 1]
            : previousResult;
          if (
            lastRule.input === currentRule.input &&
            lastRule.target === currentRule.target
          ) {
            const combinations = lastRule.combinations
              ? [
                  ...lastRule.combinations,
                  {
                    dimension: currentRule.dimension,
                    value: currentRule.value,
                  },
                ]
              : [
                  { dimension: lastRule.dimension, value: lastRule.value },
                  {
                    dimension: currentRule.dimension,
                    value: currentRule.value,
                  },
                ];
            lastRule.combinations = sortFollowAlphaBeta(combinations);
            lastRule.dimension = combinations[0].dimension;
            lastRule.value = combinations[0].value;
            return previousResult;
          } else {
            if (Array.isArray(previousResult))
              return [...previousResult, currentRule];
            else return [previousResult, currentRule];
          }
        });
  return Array.isArray(returnRules)
    ? sortFollowAlphaBeta(returnRules)
    : [returnRules];
};

export const getFilterRules = (
  record: any,
  rules: any,
  ruleAll: any,
  rulesData: any
) => {
  const filterRuleNotMandatory: any = [];
  const rowData: any = rules?.filter((room: any) => room?.id === record?.id)[0];
  if (!rowData) return { filterRuleModfify: [], filterRuleNotMandatory: [] };
  const cloneRowData = cloneDeep(rowData);
  const ruleModify = findRuleAssumption(ruleAll, cloneRowData.rules);
  const rulesNotMandatory = rulesData.filter(
    (rule: any) => !MANDATORY_DIMENSIONS.includes(rule.dimension)
  );

  const filterRuleModfify = ruleModify
    .map((rule: any) => {
      // FIND in all: GRADE, VIEW, BED, FEATURE with exact dimension and value
      // hard code, sau này sẽ sửa sau khi có requiment rõ ràng hơn
      const addRulesData = [
        ...rulesData,
        {
          dimension: "VIEW",
          value: "BEACH_FRONT",
          roomNameKr: '오션프론트',
        },
      ];
      // end hardcode
      const findedItem = addRulesData.find(
        (item: any) =>
          item.dimension === rule.dimension && item.value === rule.value
      );
      if (findedItem) {
        if (findedItem.value === "BEACH_FRONT") {
          return {
            ...rule,
            value: "OCEAN_FRONT",
            input: findedItem.roomNameKr ?? "",
          };
        }
        return { ...rule, input: findedItem.roomNameKr ?? "" };
      }
      // FIND only in FEATURE ignore dimension
      const findedItemFeature = rulesNotMandatory.find(
        (item: any) => item.value === rule.value
      );
      if (findedItemFeature) {
        filterRuleNotMandatory.push({
          ...findedItemFeature,
          dimension: "F",
          input: findedItemFeature?.roomNameKr,
          value: findedItemFeature?.value,
        });
      }
      return undefined;
    })
    .filter(Boolean);

  if (record.id === 4043) {
    console.log(filterRuleModfify);
  }

  return { filterRuleModfify, filterRuleNotMandatory };
};
