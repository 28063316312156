import { useState, useEffect } from 'react';
import { Button, Pagination, Progress, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderLayout from '../../../common/HeaderLayout';
import { useBeforeunload } from 'react-beforeunload';
import MappingResultMainData from './MappingResultMainData';
import { acceptRule, rejectRule } from '../../../store/ruleSlice';
import { getCurrentMultipleHotelIds } from '../../../store/engineResultSlice';
import {actionSetIsSimulate} from "../../../store/roomSlice";
import {useAppDispatch} from "../../../store";


function MappingResult() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  let params = useParams();
  const [loadingRoom, setLoadingRoom] = useState(true)
  const [percentageForResultHandler, setPercentageForResultHandler] = useState(0)
  const [hotelId, setHotelId] = useState('0')
  const [hotelIds, setHotelIds] = useState([])

  const handleOk = async () => {
    const result = await acceptRule(params.hotelId)
    if (result.status === 200) {
      setPercentageForResultHandler(10)
      dispatch(actionSetIsSimulate(true))
      setTimeout(() => {
        setPercentageForResultHandler(100)
        navigate(-1)
      }, 2000)
    } else alert('Something error!')
  };

  const handleCancel = async () => {
    const result = await rejectRule(params.hotelId)
    if (result.status === 200) {
      setPercentageForResultHandler(10)
      dispatch(actionSetIsSimulate(false))
      setTimeout(() => {
        setPercentageForResultHandler(100)
        navigate(-1)
      }, 2000)
    } else alert('Something error!')
  };

  useEffect(() => {
    const loadData = async () => {
      if (params.hotelId === 'multiple') {
        const hotelIds = (await getCurrentMultipleHotelIds()).data;
        if (hotelIds.length > 0) {
          setHotelIds(hotelIds)
          setHotelId(hotelIds[0])
        }
      } else setHotelId(params.hotelId as any)
    }
    loadData()
  }, [params])

  const onChange = (page: number, pageSize: number) => {
    setHotelId(hotelIds[page - 1])
  }

  useBeforeunload((event) => {
    if (percentageForResultHandler === 0)
      event.preventDefault();
  });

  return (
    <div>
      <HeaderLayout title='Mapping Result' showBack={false}>
        <Space hidden={loadingRoom}>
          <Button onClick={handleOk} danger>Confirm</Button>
          <Button onClick={handleCancel} >Reject</Button>
          <div style={{ marginLeft: 100 }} hidden={hotelIds.length === 0}>
            <b>HotelId: {hotelId}</b>
            <Pagination showQuickJumper={true} defaultCurrent={1} pageSize={1} total={hotelIds.length} onChange={onChange} />
          </div>
        </Space>
        <div hidden={percentageForResultHandler === 0}>
          <Progress percent={percentageForResultHandler} status="active" />
        </div>
      </HeaderLayout>
      <MappingResultMainData loadingRoom={loadingRoom} setLoadingRoom={setLoadingRoom} hotelId={hotelId} />
    </div>
  );
}

export default MappingResult;
