import { isEmpty } from 'lodash';
import { Button, Col, Form, Input, InputRef, Popconfirm, Row } from 'antd';
import { useEffect, useRef } from 'react';
import { CheckCircleOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useAppDispatch } from '../../store/index';
import { actionResetEditTargetRoom, updateTargetRoom } from '../../store/targetRoomSlice';
import { StoreValue } from 'antd/lib/form/interface';
function EditTargetRoom(props: any) {
  const { record } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const inputRef = useRef<InputRef>(null);
  function cancelEditTargetRoom() {
    dispatch(actionResetEditTargetRoom());
  }
  function editTargetRoom(value: StoreValue) {
    const { originalName } = form.getFieldsValue();
    const params = {
      id: record.id,
      originalName: originalName
    }
    dispatch(updateTargetRoom(params));
  }
  useEffect(() => {
    if (!isEmpty(record)) {
      inputRef.current!.focus();
    }
  }, [record]);
  return (
    <Form layout="vertical" form={form} onFinish={editTargetRoom}>
      <Row gutter={4} align="middle">
        <Col span={18}>
          <Form.Item
            name="originalName"
            initialValue={record.originalName}
            rules={[
              {
                required: true,
                message: `Target Room name is required.`,
              },
            ]}
          >
            <Input ref={inputRef} />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item>
            <Popconfirm
              placement="topRight"
              title={`Please confirm to update "${record.originalName}"!`}
              onConfirm={editTargetRoom}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" htmlType="submit" icon={<CheckCircleOutlined />} />
            </Popconfirm>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item>
            <Button type="primary" icon={<CloseCircleFilled />} onClick={cancelEditTargetRoom} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
export default EditTargetRoom;