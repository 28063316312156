import {Button, Modal, PageHeader, Table, Tooltip} from "antd";
import {setTarget} from "../store/roomSlice";
import {useAppDispatch} from "../store";
import RuleList from "../admin/room-management/components/RuleList";
import {useState} from "react";
import {InfoCircleTwoTone} from "@ant-design/icons";
import {renderTooltipMatchingRatio} from "../helper/RoomHelper";

interface IProps {
  x: number,
  y: number,
  record: any;
  visible: boolean;
  closeModal: () => void;
}


function ListTargetRoomWithRoom(props: IProps) {
  const {record, visible, closeModal } = props;
  const [rowSelected, setRowSelected] = useState<any>(null);
  const dispatch = useAppDispatch();

  const columns = [
    {
      title: 'Target Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Matching ratio',
      dataIndex: 'percentage',
      key: 'percentage',

      render: (rules: any, record: any) => (
        <p>
          {((record.percentage + record.invertPercentage) * 100 / 2).toFixed(2) + '% '}
          <Tooltip title={renderTooltipMatchingRatio(record)}><InfoCircleTwoTone /></Tooltip>
        </p>
      )
    },
    {
      title: 'Mapped',
      dataIndex: 'mappedRules',
      key: 'mappedRules',
      render: (rules: any, record: any) => (
        <RuleList rules={rules} isMandatory={true} onClick={() => { }} />
      )
    },
    {
      title: 'Upmapped',
      dataIndex: 'unMappedRules',
      key: 'unMappedRules',
      render: (rules: any, record: any) => (
        <RuleList rules={rules} isMandatory={true} onClick={() => { }} />
      )
    },
    {
      title: 'Additional Info',
      dataIndex: 'additionalRules',
      key: 'additionalRules',
      render: (rules: any, record: any) => (
        <RuleList rules={rules} isMandatory={false} onClick={() => { }} />
      )
    }
  ];
  function handleOk() {
    if(!rowSelected) {
      return;
    }
    const params = {
      roomId: record.id,
      targetRoomId: rowSelected.id
    }
    dispatch(setTarget(params));
  }
  return (
    <>
        { visible && (
          <Modal onCancel={() => closeModal()} footer={[
            <Button key="submit" type="primary" onClick={handleOk}>
              OK
            </Button>,
          ]} open width={1080}>
            <PageHeader
              className="site-page-header"
              title={record.name}
              subTitle={<><RuleList rules={record.rules} isMandatory={true} onClick={() => { }} />
                <RuleList rules={record.rules} isMandatory={false} onClick={() => { }} />
                { !rowSelected && <i style={{color: 'red'}}>Please choose target room </i> }</>}
            />
            <Table onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if ((rowSelected && (record.id !== rowSelected.id)) || !rowSelected) {
                    setRowSelected({...record})
                    return
                  }
                  setRowSelected(null)
                },
              };
            }} dataSource={record.targetRoomAll}
            rowClassName={(item) => (item.id === rowSelected?.id ? 'ant-table-row-selected' : '')} columns={columns} pagination={false} scroll={{y: 450}} rowKey='name' />
          </Modal>
        )}
    </>
  );
}

export default ListTargetRoomWithRoom;