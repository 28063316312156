import { useState, useEffect, useRef } from "react";
import { Button, Input, InputRef, Space, Table } from "antd";
import { Link } from "react-router-dom";
import {
  ColumnType,
  FilterConfirmProps,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import { HOTEL_STATUS } from "../constants/HotelConst";
import HeaderLayout from "../common/HeaderLayout";
import { SearchOutlined } from "@ant-design/icons";
import { LIMIT_PAGESIZE_DEFAULT, getNation } from "../constants/app";

import { useAppDispatch, useAppSelector } from "../store";
import {
  actionGetListHotel,
  selectedListHotel,
  selectedLoadingGetListHotel,
  selectedFilterHotel,
} from "../store/hotelSlice";
import { NATIONS } from "../constants/app";

interface IProps {
  statusRoomChange: any;
}

interface DataType {
  key: string;
  id: number;
  name: string;
  age: number;
  address: string;
  status: number;
}

type DataIndex = keyof DataType;

function Home(props: IProps) {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [paramsSearch, setParamsSearch] = useState({
    hotelId: 0,
    hotelName: "",
    nation: "",
    cityId: "",
    page: 0,
    size: LIMIT_PAGESIZE_DEFAULT,
  });
  const filterHotelSelected = useAppSelector(selectedFilterHotel);
  const listHotel = useAppSelector(selectedListHotel);
  const loading = useAppSelector(selectedLoadingGetListHotel);
  const searchInput = useRef<InputRef>(null);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as any}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: "Nation",
      dataIndex: "nation",
      render: (text: any, record: any) => (
        <>
          <img src={getNation(record.nation)} alt="" width={30} /> {NATIONS.find(nation => nation.value === record.nation)?.label || ''}
        </>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "HotelId",
      dataIndex: "id",
    },
    {
      title: "Hotel name",
      dataIndex: "name",
      render: (text: any, record: any) => <>{record.name}</>,
    },
    {
      title: "New Target Room",
      dataIndex: "new_target_room_cnt",
      render: (text: any, record: any) => (
        <>
          {" "}
          <Link to={"/target-room-management/" + record.id}>
            {record.new_target_room_cnt || 0}
          </Link>
        </>
      ),
    },
    {
      title: "New Room",
      dataIndex: "new_room_cnt",
      render: (text: any, record: any) => (
        <>
          {" "}
          <Link to={"/room-management/" + record.id}>
            {record.new_room_cnt || 0}
          </Link>
        </>
      ),
    },
    {
      title: "Mapped Room / Total Room",
      render: (text: any, record: any) => (
        <>
          {" "}
          <Link to={"/room-management/" + record.id}>
            {record.mapped_room_cnt} / {record.total_room_cnt}
          </Link>
        </>
      ),
    },
  ];

  const onChange = async (
    pagination: any,
    filters: any,
    sorter: any,
    extra: TableCurrentDataSource<any>
  ) => {
    setParamsSearch({
      ...paramsSearch,
      hotelId: filters.id ? filters.id[0] : "",
      hotelName: filters.name ? filters.name[0] : "",
      page: pagination.current === undefined ? 0 : pagination.current - 1,
      size: pagination.pageSize,
    });
  };

  useEffect(() => {
    setParamsSearch({
      ...paramsSearch,
      ...filterHotelSelected,
    });
  }, [filterHotelSelected, paramsSearch]);

  useEffect(() => {
    dispatch(
      actionGetListHotel({
        page: paramsSearch.page,
        size: paramsSearch.size,
        hotelId: paramsSearch.hotelId,
        hotelName: paramsSearch.hotelName,
        nation: paramsSearch.nation,
        cityId: paramsSearch.cityId,
      })
    );
  }, [
    paramsSearch.hotelId,
    paramsSearch.hotelName,
    paramsSearch.page,
    paramsSearch.size,
    paramsSearch.nation,
    paramsSearch.cityId,
    dispatch,
  ]);

  useEffect(() => {
    const { content, totalElements } = listHotel;
    setData(content);
    setTotal(totalElements);
  }, [listHotel]);

  useEffect(() => {
    if (props.statusRoomChange) {
      const status =
        props.statusRoomChange.action === "join"
          ? HOTEL_STATUS.WORKING
          : HOTEL_STATUS.AVAILABLE;
      const newData = data.map((hotel: any) => {
        if (props.statusRoomChange.data.includes(hotel.hotel_id)) {
          hotel.status = status;
          if (status === HOTEL_STATUS.AVAILABLE) hotel.connectionId = undefined;
          else hotel.connectionId = props.statusRoomChange.connectionId;
        }
        return hotel;
      });
      setData([...newData] as any);
    }
  }, [data, props.statusRoomChange]);

  return (
    <div className="Home">
      <HeaderLayout title="Hotel Management" showBack={false}>
        
      </HeaderLayout>
      <Table
        loading={loading}
        rowClassName={(record, index) =>
          record.status === HOTEL_STATUS.AVAILABLE
            ? "table-row-light"
            : "table-row-2"
        }
        rowKey={"id"}
        columns={columns}
        dataSource={data}
        pagination={{ total: total, defaultPageSize: LIMIT_PAGESIZE_DEFAULT }}
        onChange={onChange}
        scroll={{ y: window.innerHeight - 300 }}
      />
    </div>
  );
}

export default Home;
